import {
  PrimaryButton,
  SearchBarWithLabel,
  SecondaryButton,
} from "@collabodoc/component-library";
import { faClose, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import styled from "styled-components";
import { device } from "../../Content/Style/devices";
import { MedicalRoundContext } from "../../context/MedicalRoundContext";
import { FILTER_KEYS, STATUS_FILTER_NAME } from "../../enums/enums";
import usePatientListFilters from "../../hooks/usePatientListFilters";

export const PageHeader = ({
  setShowCreateIssueModal,
  handleSearchInput,
  setShowSideMenu,
  showSideMenu,
}) => {
  const { careCenters, contactReasons } = useContext(MedicalRoundContext);
  const { isFilterSelected, toggleFilterSelected } = usePatientListFilters();

  const selectedCareCenters = careCenters
    .filter(({ careCenterId }) =>
      isFilterSelected(FILTER_KEYS.CARE_CENTER_ID, careCenterId)
    )
    .map((c) => ({
      id: c.careCenterId.toString(),
      name: FILTER_KEYS.CARE_CENTER_ID,
      label: c.name,
    }));

  const selectedUnits = careCenters
    .flatMap((c) => c.units)
    .filter(({ unitId }) => isFilterSelected(FILTER_KEYS.UNIT_ID, unitId))
    .map((u) => ({
      id: u.unitId.toString(),
      name: FILTER_KEYS.UNIT_ID,
      label: u.name,
    }));

  const selectedStatuses = Object.entries(STATUS_FILTER_NAME)
    .filter(([key]) => isFilterSelected(FILTER_KEYS.STATUS, key))
    .map(([key, value]) => ({
      id: key,
      name: FILTER_KEYS.STATUS,
      label: value,
    }));

  const selectedContactReasons = contactReasons
    .filter((c) => isFilterSelected(FILTER_KEYS.CONTACT_REASON, c.id))
    .map((c) => ({
      name: FILTER_KEYS.CONTACT_REASON,
      label: c.name,
      id: c.id,
    }));

  const selectedShowOnlyPatientsWithNotes = isFilterSelected(
    FILTER_KEYS.ONLY_WITH_NOTES,
    true
  )
    ? [
        {
          id: true,
          name: FILTER_KEYS.ONLY_WITH_NOTES,
          label: "Patienter med privat anteckning",
        },
      ]
    : [];

  const allFilters = selectedStatuses
    .concat(selectedCareCenters)
    .concat(selectedUnits)
    .concat(selectedShowOnlyPatientsWithNotes)
    .concat(selectedContactReasons);

  return (
    <Header>
      <UpperHeader>
        <HeaderGroup>
          <StyledSecondaryButton onClick={() => setShowSideMenu(!showSideMenu)}>
            <Icon icon={showSideMenu ? faClose : faFilter} />
            {showSideMenu ? "Stäng filtrering" : "Filtrera"}
          </StyledSecondaryButton>
          <StyledPrimaryButton onClick={() => setShowCreateIssueModal(true)}>
            Lägg till patient
          </StyledPrimaryButton>
        </HeaderGroup>
        <SearchBarWrapper>
          <SearchBarWithLabel
            placeholder={"Sök namn/personnummer"}
            handleChange={handleSearchInput}
          ></SearchBarWithLabel>
        </SearchBarWrapper>
      </UpperHeader>
      <FilterButtonGroup>
        {allFilters.map(({ name, id, label }, index) => (
          <FilterButton
            key={index}
            onClick={() => toggleFilterSelected(name, id)}
          >
            <FontAwesomeIcon icon={faClose} />
            {label}
          </FilterButton>
        ))}
      </FilterButtonGroup>
    </Header>
  );
};

const FilterButtonGroup = styled.div`
  display: block;
`;

const FilterButton = styled.button`
  border-radius: 5px;
  background-color: white;
  border: 1px solid #757575;
  color: #757575;
  margin: 0 5px 5px 0;
  padding: 2px 7px 2px 7px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: small;

  > svg {
    margin-right: 4px;
  }
`;

const SearchBarWrapper = styled.div`
  span {
    z-index: 0;
  }

  @media ${device.laptopS} {
    input {
      width: 250px;
    }
  }
`;

const Header = styled.section`
  margin-right: 15px;
  margin-left: 15px;
`;

const UpperHeader = styled.section`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 0.75rem;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const HeaderGroup = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;

  @media ${device.laptopM} {
    flex-direction: column;
  }
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  min-width: 155px;
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  min-width: 155px;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 4px;
`;
