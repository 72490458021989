import {
  CheckboxWithLabel,
  TextInputWithLabelAndError,
} from "@collabodoc/component-library";
import { useContext } from "react";
import styled from "styled-components";
import { MedicalRoundContext } from "../../context/MedicalRoundContext";

const MeasureSection = ({
  measures,
  setMeasures,
  selectedMeasureTemplates,
  setSelectedMeasureTemplates,
}) => {
  const { measureTemplates = [] } = useContext(MedicalRoundContext);

  const handleMeasureState = (templateId) => {
    setSelectedMeasureTemplates((prevState) =>
      prevState.includes(templateId)
        ? selectedMeasureTemplates.filter((m) => m !== templateId)
        : [...prevState, templateId]
    );

    //Add measure when checkbox is first checked
    handleFormChange(templateId);

    //Remove measure if checkbox is unchecked
    if (measures.some((m) => m.measureTemplateId === templateId)) {
      setMeasures(measures.filter((m) => m.measureTemplateId !== templateId));
    }
  };

  const handleFormChange = (templateId, phrase = "") => {
    //Update measure phrase if measure already exists, else add new measure
    if (measures.some((m) => m.measureTemplateId === templateId)) {
      let arr = measures.filter((m) => m.measureTemplateId !== templateId);
      arr.push({ measureTemplateId: templateId, phrase: phrase });

      setMeasures(arr);
    } else {
      setMeasures((prevState) => [
        ...prevState,
        { measureTemplateId: templateId, phrase: phrase },
      ]);
    }
  };

  return (
    <Measures>
      {measureTemplates.map((measureTemplate) => (
        <MeasureWrapper key={measureTemplate.id}>
          <CheckboxWithLabel
            label={measureTemplate.name}
            checked={selectedMeasureTemplates.includes(measureTemplate.id)}
            handleChange={() => handleMeasureState(measureTemplate.id)}
          />
          {selectedMeasureTemplates.includes(measureTemplate.id) && (
            <TextInputWithLabelAndError
              value={
                measures.find((m) => m.measureTemplateId === measureTemplate.id)
                  ? measures.find(
                      (m) => m.measureTemplateId === measureTemplate.id
                    ).phrase
                  : ""
              }
              handleChange={(e) => handleFormChange(measureTemplate.id, e)}
            />
          )}
        </MeasureWrapper>
      ))}
    </Measures>
  );
};

const Measures = styled.div`
  margin-bottom: 15px;
  overflow-y: auto;
  overflow-x: hidden;
  word-wrap: break-word;
`;

const MeasureWrapper = styled.div`
  > label {
    color: #0d3050;
    margin: 0;

    > span::before {
      border-radius: 3px;
    }
  }

  > input {
    margin-bottom: 5px;
    border-radius: 3px;
  }
`;
export default MeasureSection;
