const ISSUE_STATUS = {
  OPEN: 1,
  COMPLETED: 2,
};

const USER_ROLES = {
  DoctorUser: 1,
  NurseUser: 2,
  RegistratorUser: 3,
  SecretaryUser: 4,
  PhysiotherapistUser: 5,
  PsychologistUser: 6,
  CounselorUser: 7,
  OccupationalTherapistUser: 8,
};

const USER_ROLE_NAMES = {
  1: "Läkare",
  2: "Sjuksköterska",
  4: "Sekreterare",
};

const STATUS_FILTER = {
  NURSE_USER: "Nurse",
  DOCTOR_USER: "Doctor",
  SECRETARY_USER: "Secretary",
  COMPLETED: "Completed",
};

const STATUS_FILTER_NAME = {
  [STATUS_FILTER.NURSE_USER]: "Öppna ärenden, sjuksköterska",
  [STATUS_FILTER.DOCTOR_USER]: "Öppna ärenden, läkare",
  [STATUS_FILTER.SECRETARY_USER]: "Öppna ärenden, sekreterare",
  Completed: "Avslutade ärenden",
};

const ACTIVE_TAB = {
  ALL_ISSUES_TAB: "allissues",
  SELECTED_ISSUE_TAB: "selectedissue",
  NEW_ISSUE_TAB: "newissue",
};

const ACTIVE_REMINDER_TAB = {
  ALL_REMINDERS_TAB: "allreminders",
  NEW_REMINDER_TAB: "newreminder",
};

const FILTER_KEYS = {
  CARE_CENTER_ID: "careCenterId",
  UNIT_ID: "unitId",
  STATUS: "status",
  ONLY_WITH_NOTES: "onlywithnotes",
  PAGE: "page",
  CONTACT_REASON: "contactReason",
};

const FILTERS_TO_DELETE = {
  [FILTER_KEYS.CARE_CENTER_ID]: [FILTER_KEYS.PAGE, FILTER_KEYS.UNIT_ID],
  [FILTER_KEYS.UNIT_ID]: [FILTER_KEYS.PAGE],
  [FILTER_KEYS.STATUS]: [FILTER_KEYS.PAGE],
  [FILTER_KEYS.ONLY_WITH_NOTES]: [FILTER_KEYS.PAGE],
  [FILTER_KEYS.PAGE]: [],
};

export {
  ISSUE_STATUS,
  STATUS_FILTER,
  STATUS_FILTER_NAME,
  ACTIVE_TAB,
  FILTER_KEYS,
  FILTERS_TO_DELETE,
  USER_ROLES,
  USER_ROLE_NAMES,
  ACTIVE_REMINDER_TAB,
};
