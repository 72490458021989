import {
  CardBody,
  CardComponent,
  CardHeader,
  CardHeaderText,
} from "../../Content/Style/cardstyles";
import MessageSection from "./MessageSection";
import { device } from "../../Content/Style/devices";
import styled from "styled-components";

const MessageHistory = ({ issue, isIssueOpen, roleIsInIssue }) => {
  return (
    <StyledIssueCard>
      <StyledCardHeader>
        <CardHeaderText>
          {issue && issue.contactReason ? issue.contactReason : "Nytt ärende"}
        </CardHeaderText>
      </StyledCardHeader>
      <IssueCardBody>
        {issue && issue.contactReason && (
          <MessageSection
            patientId={issue.patientId}
            isIssueClosed={!isIssueOpen}
            content={issue && issue.content}
            issueId={issue && issue.issueId}
            roleIsInIssue={roleIsInIssue}
          />
        )}
      </IssueCardBody>
    </StyledIssueCard>
  );
};

const StyledCardHeader = styled(CardHeader)`
  @media ${device.tablet} {
    display: none;
  }
`;

const StyledIssueCard = styled(CardComponent)`
  flex: 4;
  height: 83vh;
  margin-bottom: 0;

  @media ${device.tablet} {
    height: auto;
  }
`;

const IssueCardBody = styled(CardBody)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media ${device.tablet} {
    overflow-y: inherit;
  }
`;

export default MessageHistory;
