import { useOidcAccessToken, useOidcUser } from "@axa-fr/react-oidc";
import {
  doFetch,
  fileUploadValidation,
  getClinicRole,
  getRequestInit,
  LoadingView,
  PrimaryButton,
  SecondaryButton,
  TextAreaWithLabelAndError,
  TextButton,
  useFetchy,
  ValidationError,
} from "@collabodoc/component-library";
import {
  faPaperclip,
  faPaperPlane,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useRef, useState } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import { useMediaQuery } from "react-responsive";
import { useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import {
  CardBody,
  CardComponent,
  CardFooter,
  CardHeader,
  CardHeaderText,
} from "../../Content/Style/cardstyles";
import { device } from "../../Content/Style/devices";
import { MedicalRoundContext } from "../../context/MedicalRoundContext";
import { USER_ROLE_NAMES, USER_ROLES } from "../../enums/enums";
import { API_URLS } from "../../enums/Urls";
import { CloseIssueButton } from "./CloseIssueButton";
import MeasureSection from "./MeasureSection";
import { IssueIdKey } from "./PatientView";

const FILE_VALIDATION_SETTINGS = {
  acceptedFileTypes: [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "application/pdf",
  ],
  maxMBFileSize: 5,
  maxFileNameLength: 100,
};

const NewMessage = ({ issue, isIssueOpen, handleIsSelectedIssueSection }) => {
  const { patientId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams(IssueIdKey);
  const [contactReason, setContactReason] = useState();
  const { contactReasons } = useContext(MedicalRoundContext);
  const { accessToken } = useOidcAccessToken();
  const { oidcUser } = useOidcUser();
  const clinicRole = getClinicRole(oidcUser?.role);
  const { measureTemplates = [] } = useContext(MedicalRoundContext);

  const fileInputRef = useRef();
  const [messageText, setMessageText] = useState("");
  const [files, setFiles] = useState([]);
  const [measures, setMeasures] = useState([]);
  const [selectedMeasureTemplates, setSelectedMeasureTemplates] = useState([]);
  const [errors, setErrors] = useState([]);

  const { doFetch: sendMessage, isLoading } = useFetchy();
  const isLaptopM = useMediaQuery({ query: device.laptopM });

  const handleSendMessage = async () => {
    const data = new FormData();

    files.forEach((file) => {
      data.append("attachments", file);
    });

    data.append("text", messageText);

    if (messageText) {
      const url = API_URLS.PATIENT_ISSUE_MESSAGE(
        issue && issue.patientId,
        issue && issue.issueId
      );
      const init = getRequestInit({ accessToken, method: "POST" });
      init.body = data;
      await sendMessage(url, init).then(({ response }) => {
        if (response.ok) {
          setMessageText("");
          setFiles([]);
          setErrors([]);
        } else {
          setErrors(["Meddelandet kunde inte skickas"]);
        }
      });
    }
  };

  const handleSendMeasure = async () => {
    let data = [];

    measures.forEach((measure) =>
      data.push({
        measureTemplateId: measure.measureTemplateId,
        phrase: measure.phrase,
      })
    );

    if (data.length > 0) {
      const url = API_URLS.PATIENT_ISSUE_MEASURES(
        issue && issue.patientId,
        issue && issue.issueId
      );
      await fetch(
        url,
        getRequestInit({ accessToken, method: "POST", data })
      ).then((response) => {
        if (response.ok) {
          setMeasures([]);
          setSelectedMeasureTemplates([]);
        }
      });
    }
  };

  const handleSendContent = async () => {
    await handleSendMeasure();
    await handleSendMessage();
  };

  const handleContactReason = (id) => {
    const cr = contactReasons.find((x) => x.id === parseInt(id));
    setContactReason({ id: parseInt(id), name: cr.name });
  };

  const handleCreateIssueButtonClick = async () => {
    const data = new FormData();
    data.append("contactReason", contactReason.id);
    measures.forEach((measure, i) => {
      for (let property in measure) {
        data.append(`measures[${i}][${property}]`, measure[property]);
      }
    });
    files.forEach((file) => {
      data.append("attachments", file);
    });
    data.append("text", messageText);
    const url = API_URLS.PATIENT_ISSUES(patientId);
    const init = getRequestInit({ accessToken, method: "POST" });
    init.body = data;
    await doFetch(url, init).then(({ response, data: issueId }) => {
      if (response.ok) {
        searchParams.set(IssueIdKey, issueId.toString());
        setSearchParams(searchParams, { replace: true });
        handleIsSelectedIssueSection(issueId);
        setMessageText("");
        setFiles([]);
        setErrors([]);
        setMeasures([]);
        setSelectedMeasureTemplates([]);
      } else {
        setErrors(["Ärendet kunde inte skapas"]);
      }
    });
  };

  const handleFileChange = ({ target }) => {
    const [allFiles, errors] = fileUploadValidation(
      target.files,
      files,
      FILE_VALIDATION_SETTINGS
    );
    setErrors(errors);
    setFiles(allFiles);
  };

  const handleDeleteFile = (index) => {
    files.splice(index, 1);
    setFiles([...files]);
    fileInputRef.current.value = null; // Needed so that onchange event will be triggered if file is uploaded again
  };

  const contactReasonsFiltered =
    (contactReasons &&
      contactReasons.filter((cr) => cr.fromRole === USER_ROLES[clinicRole])) ||
    [];

  const contactReasonsGroupedByToRole = contactReasonsFiltered.reduce(
    (acc, obj) => {
      const key = obj["toRole"];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    },
    {}
  );

  return (
    <IssueCard>
      {isLoading && <LoadingView />}
      <StyledCardHeader>
        <CardHeaderText>
          {issue && issue.contactReason
            ? "Meddelande" +
              (measureTemplates.length ? " / Åtgärdsbegäran" : "")
            : "Skapa nytt ärende"}
        </CardHeaderText>
      </StyledCardHeader>
      <MessageCardBody $loading={isLoading}>
        {!issue && (
          <FlexSpaceBetween>
            <ContactReasonDropDown>
              <Dropdown
                size="md"
                as={ButtonGroup}
                drop="center"
                onSelect={(e) => handleContactReason(e)}
                align={"start"}
              >
                <Dropdown.Toggle id="contactreasons-dropdown">
                  {(contactReason && contactReason.name) ||
                    (USER_ROLES[clinicRole] === USER_ROLES.NurseUser
                      ? "Välj kontaktorsak (Vid akuta ärenden, ring!)"
                      : "Välj kontaktorsak")}
                </Dropdown.Toggle>
                <StyledDropdownMenu>
                  {Object.entries(contactReasonsGroupedByToRole).map(
                    (group, index) => (
                      <div key={index}>
                        {
                          <StyledDropdownHeader>
                            Till {USER_ROLE_NAMES[group[0]]}
                          </StyledDropdownHeader>
                        }
                        {group[1].map((cr) => (
                          <StyledDropdownItem key={cr.id} eventKey={cr.id}>
                            {cr.name}
                          </StyledDropdownItem>
                        ))}
                      </div>
                    )
                  )}
                </StyledDropdownMenu>
              </Dropdown>
            </ContactReasonDropDown>
          </FlexSpaceBetween>
        )}
        {(isIssueOpen || !issue) && (
          <MeasureSection
            measures={measures}
            setMeasures={setMeasures}
            selectedMeasureTemplates={selectedMeasureTemplates}
            setSelectedMeasureTemplates={setSelectedMeasureTemplates}
          />
        )}
        {clinicRole && (isIssueOpen || !issue) && (
          <NewMessageWrapper>
            <FileList>
              {files.map((file, index) => (
                <li key={index}>
                  <RemoveFileButton onClick={() => handleDeleteFile(index)}>
                    <FontAwesomeIcon icon={faXmark} />
                  </RemoveFileButton>
                  {file.name}
                </li>
              ))}
            </FileList>
            {errors.map((error, index) => (
              <FileValidationError key={index}>{error}</FileValidationError>
            ))}
            <TextSection>
              <TextAreaWithLabelAndError
                disabled={isLoading}
                maxRows={4}
                minRows={4}
                handleChange={(e) => setMessageText(e)}
                value={messageText}
              />
            </TextSection>
          </NewMessageWrapper>
        )}
      </MessageCardBody>

      <Footer>
        {isIssueOpen ? (
          <CloseIssueButton
            issue={issue}
            currentUser={oidcUser && oidcUser.sub}
          />
        ) : (
          <div></div>
        )}
        {(isIssueOpen || !issue) && (
          <RightButtons>
            <SecondaryButton
              onClick={() => fileInputRef.current.click()}
              disabled={isLoading}
            >
              <Icon icon={faPaperclip} />
              {!isLaptopM && "Bifoga"}
              <input
                hidden
                ref={fileInputRef}
                onChange={handleFileChange}
                type="file"
                multiple
                accept={FILE_VALIDATION_SETTINGS.acceptedFileTypes.join(", ")}
              />
            </SecondaryButton>
            {isIssueOpen && (
              <PrimaryButton
                disabled={!messageText && measures.length < 1}
                onClick={handleSendContent}
              >
                <Icon icon={faPaperPlane} />
                {!isLaptopM && "Skicka"}
              </PrimaryButton>
            )}
            {!issue && (
              <PrimaryButton
                disabled={
                  !contactReason ||
                  (!messageText && measures.length === 0) ||
                  (!messageText && files.length > 0)
                }
                onClick={handleCreateIssueButtonClick}
              >
                Skapa ärende
              </PrimaryButton>
            )}
          </RightButtons>
        )}
      </Footer>
    </IssueCard>
  );
};

const IssueCard = styled(CardComponent)`
  flex: 3;
  height: 83vh;
  margin-bottom: 0;
`;

const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledCardHeader = styled(CardHeader)`
  @media ${device.tablet} {
    display: none;
  }
`;

const ContactReasonDropDown = styled.div`
  width: 100%;

  > div {
    width: 50%;

    > button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: ${({ theme }) => theme.input.dropDown.backgroundColor};
      border-color: ${({ theme }) => theme.input.dropDown.border.color};
      color: ${({ theme }) => theme.input.dropDown.border.color};
      box-shadow: none;
      border-radius: 3px;

      &:hover {
        background-color: ${({ theme }) =>
          theme.input.dropDown.backgroundColor};
        border-color: ${({ theme }) => theme.input.dropDown.border.color};
        color: ${({ theme }) => theme.input.dropDown.border.color};
        box-shadow: none;
      }

      &:focus:active {
        background-color: ${({ theme }) =>
          theme.input.dropDown.backgroundColor};
        border-color: ${({ theme }) => theme.input.dropDown.border.color};
        color: ${({ theme }) => theme.input.dropDown.border.color};
        box-shadow: none;
      }
    }

    .btn-primary.dropdown-toggle {
      background-color: ${({ theme }) => theme.input.dropDown.backgroundColor};
      border-color: ${({ theme }) => theme.input.dropDown.border.color};
      color: ${({ theme }) => theme.input.dropDown.border.color};
      box-shadow: none;
    }

    .btn-primary.dropdown-toggle:focus {
      background-color: ${({ theme }) => theme.input.dropDown.backgroundColor};
      border-color: ${({ theme }) => theme.input.dropDown.border.color};
      color: ${({ theme }) => theme.input.dropDown.border.color};
      box-shadow: none;
    }

    .btn-primary.dropdown-toggle:active {
      background-color: ${({ theme }) => theme.input.dropDown.backgroundColor};
      border-color: ${({ theme }) => theme.input.dropDown.border.color};
      color: ${({ theme }) => theme.input.dropDown.border.color};
      box-shadow: none;
    }

    .btn-primary.dropdown-toggle:active:focus {
      background-color: ${({ theme }) => theme.input.dropDown.backgroundColor};
      border-color: ${({ theme }) => theme.input.dropDown.border.color};
      color: ${({ theme }) => theme.input.dropDown.border.color};
      box-shadow: none;
    }
  }
`;

const StyledDropdownMenu = styled(Dropdown.Menu)`
  padding: 0;
  border: 1px solid ${({ theme }) => theme.input.dropDown.border.color};
  border-radius: 3px;
  overflow: hidden;
  width: 100%;
  max-height: 60vh;
  overflow-y: auto;
`;

const StyledDropdownHeader = styled(Dropdown.Header)`
  background-color: ${({ theme }) => theme.dropdown.groupHeaderColor};
  color: ${({ theme }) => theme.input.dropDown.border.color};
  padding: 0.5rem;
`;

const StyledDropdownItem = styled(Dropdown.Item)`
  padding: 0.5rem 1.5rem;
  white-space: initial;

  &:hover {
    background-color: ${({ theme }) =>
      theme.input.dropDown.hover.backgroundColor};
  }

  &:active {
    color: ${({ theme }) => theme.input.dropDown.border.color};
  }
`;

const RightButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const TextSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: end;

  > textarea {
    border-radius: 3px;
  }
`;

const NewMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;

  @media ${device.tablet} {
    margin-bottom: 12px;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  margin-top: 4px;
  margin-right: 4px;
`;

const FileList = styled.ul`
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;

  li {
    display: inline - block;
    padding-right: 15px;
    font-size: 14px;
    list-style: none;
  }
`;

const RemoveFileButton = styled(TextButton)`
  padding-right: 4px;
  padding-left: 0px;

  &:hover {
    color: darkred;
  }
`;

const FileValidationError = styled(ValidationError)`
  font-size: 14px;
`;

const MessageCardBody = styled(CardBody)`
  overflow-y: inherit;
  display: flex;
  flex-direction: column;
  opacity: ${(props) => (props.$loading ? "0.5" : "1")};
`;

const Footer = styled(CardFooter)`
  display: flex;
  justify-content: space-between;
  height: 4rem;
  padding: 0.75rem;
`;

const WarningAcute = styled.div`
  color: red;
  width: 100%;
  margin-top: 2px;
  font-size: 10px;
`;

export default NewMessage;
