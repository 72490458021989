interface StringMap {
  [key: string]: string;
}
export const sortAscendingByKey = (list: StringMap[] = [], key: string) =>
  list.sort((a, b) => {
    const aValue = a[key] && a[key].toLowerCase();
    const bValue = b[key] && b[key].toLowerCase();
    return bValue < aValue ? 1 : bValue > aValue ? -1 : 0;
  });

export const sortAscending = (a: string, b: string): number => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};
