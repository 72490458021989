import { OidcProvider, OidcSecure } from "@axa-fr/react-oidc";
import { LoadingView } from "@collabodoc/component-library";
import { Container } from "react-bootstrap";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import AdminView from "./components/AdminView/AdminView";
import NavMenu from "./components/NavMenu";
import PatientListRedirect from "./components/PatientListView/PatientListRedirect";
import PatientListView from "./components/PatientListView/PatientListView";
import PatientView from "./components/PatientView/PatientView";
import { FontProvider } from "./Content/fonts";
import { getTheme } from "./Content/themes/getTheme";
import { MedicalRoundContextProvider } from "./context/MedicalRoundContext";
import { SITE_URLS } from "./enums/Urls";
import oidcConfiguration from "./OidcConfiguration";
import { CustomSessionLost } from "./components/CustomSessionLost";

const themeSettings = getTheme();

const App = () => (
  <ThemeProvider theme={themeSettings.theme}>
    <FontProvider />
    <BrowserRouter>
      <OidcProvider
        configuration={oidcConfiguration}
        authenticatingComponent={LoadingView}
        callbackSuccessComponent={LoadingView}
        sessionLostComponent={CustomSessionLost}
        loadingComponent={LoadingView}
      >
        <OidcSecure>
          <MedicalRoundContextProvider>
            <StyledContainer fluid>
              <NavMenu themeSettings={themeSettings} />
              <Routes>
                <Route
                  path={SITE_URLS.PATIENT_LIST}
                  element={<PatientListView />}
                />
                <Route
                  path={SITE_URLS.PATIENT_VIEW}
                  element={<PatientView />}
                />
                <Route path={SITE_URLS.ADMIN_VIEW} element={<AdminView />} />
                <Route path={"/"} element={<PatientListRedirect />} />
              </Routes>
            </StyledContainer>
          </MedicalRoundContextProvider>
        </OidcSecure>
      </OidcProvider>
    </BrowserRouter>
  </ThemeProvider>
);

const StyledContainer = styled(Container)`
  padding-left: 0;
  padding-right: 0;
  padding-top: 57px;
  height: 100vh;
`;

export default App;
