import { getClinicRole, H5 } from "@collabodoc/component-library";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import {
  CardComponent,
  CardHeader,
  CardHeaderText,
} from "../../Content/Style/cardstyles";
import { device } from "../../Content/Style/devices";
import { MedicalRoundContext } from "../../context/MedicalRoundContext";
import { ISSUE_STATUS, USER_ROLES } from "../../enums/enums";
import getStatusIcon from "../../functions/getIcon";
import { formatDateTime } from "../../utils/dateUtils";

const PatientIssues = ({ issues = [], setSelected, selectedIssueId }) => {
  const { oidcUser } = useContext(MedicalRoundContext);
  const clinicRole = getClinicRole(oidcUser?.role);
  const unAnsweredIssues = issues.filter(
    (issue) =>
      issue.status !== ISSUE_STATUS.COMPLETED &&
      issue.currentRole === USER_ROLES[clinicRole]
  );
  const awaitingAnswer = issues.filter(
    (issue) =>
      issue.status !== ISSUE_STATUS.COMPLETED &&
      issue.currentRole !== USER_ROLES[clinicRole] &&
      issue.otherRole === USER_ROLES[clinicRole]
  );
  const otherIssues = issues.filter(
    (issue) =>
      issue.status !== ISSUE_STATUS.COMPLETED &&
      issue.currentRole !== USER_ROLES[clinicRole] &&
      issue.otherRole !== USER_ROLES[clinicRole]
  );
  const closedIssues = issues.filter(
    (issue) => issue.status === ISSUE_STATUS.COMPLETED
  );

  return (
    <>
      <PatientIssueList
        label={"Obesvarade"}
        issues={unAnsweredIssues}
        selectedIssueId={selectedIssueId}
        setSelected={setSelected}
      />
      <PatientIssueList
        label={"Inväntar svar"}
        issues={awaitingAnswer}
        selectedIssueId={selectedIssueId}
        setSelected={setSelected}
      />
      <PatientIssueList
        label={"Övriga"}
        issues={otherIssues}
        selectedIssueId={selectedIssueId}
        setSelected={setSelected}
      />
      <PatientIssueList
        label={"Avslutade"}
        issues={closedIssues}
        selectedIssueId={selectedIssueId}
        setSelected={setSelected}
      />
    </>
  );
};

const PatientIssueList = ({
  issues = [],
  label,
  selectedIssueId,
  setSelected,
}) => {
  const isTablet = useMediaQuery({ query: device.tablet });
  const [showIssues, setShowIssues] = useState(false);

  useEffect(() => {
    const hasSelectedIssue = issues.some(
      (issue) => issue.issueId === selectedIssueId
    );
    if (hasSelectedIssue) {
      setShowIssues(true);
    }
  }, [issues, selectedIssueId]);

  return (
    <>
      {label !== "Avslutade" && (
        <StyledCardComponent>
          <StyledCardHeader
            $show={showIssues}
            onClick={() => setShowIssues(!showIssues)}
          >
            <StyledCardHeaderText>
              {label} {`(${issues.length})`}
            </StyledCardHeaderText>
            <FontAwesomeIcon icon={showIssues ? faChevronUp : faChevronDown} />
          </StyledCardHeader>
          <CardBodyBorder $show={showIssues}>
            {issues.length > 0 ? (
              <>
                {isTablet && (
                  <IssueTableHeader>
                    <SmallText>Ärende</SmallText>
                    <SmallText>Senaste händelse</SmallText>
                  </IssueTableHeader>
                )}
              </>
            ) : (
              <IssueTableHeader>
                <SmallText>Inga ärenden</SmallText>
              </IssueTableHeader>
            )}
            {issues.map((issue) => (
              <IssueRow
                key={issue.issueId}
                isSelected={selectedIssueId === issue.issueId}
                onClick={() => setSelected(issue.issueId)}
              >
                <Line />
                <IssueDiv>
                  <IssueHeader>
                    <Icon>
                      {" "}
                      <Image alt="" src={getStatusIcon(issue.currentRole)} />
                    </Icon>
                    {issue.contactReason}
                  </IssueHeader>
                  <LatestEvent>
                    {formatDateTime(issue.timeOfLastActivity) ||
                      "Inga händelser"}
                  </LatestEvent>
                </IssueDiv>
              </IssueRow>
            ))}
          </CardBodyBorder>
        </StyledCardComponent>
      )}

      {label === "Avslutade" && (
        <StyledCardComponent>
          <DisabledCardHeader
            $show={showIssues}
            onClick={() => setShowIssues(!showIssues)}
          >
            <DisabledCardHeaderText>
              Avslutade {`(${issues.length})`}
            </DisabledCardHeaderText>
            <FontAwesomeIcon icon={showIssues ? faChevronUp : faChevronDown} />
          </DisabledCardHeader>
          <CardBodyBorder $show={showIssues} closed={true}>
            {issues.length > 0 ? (
              <>
                {isTablet && (
                  <IssueTableHeader>
                    <SmallText>Ärende</SmallText>
                    <SmallText>Senaste händelse</SmallText>
                  </IssueTableHeader>
                )}
              </>
            ) : (
              <IssueTableHeader>
                <SmallText>Inga ärenden</SmallText>
              </IssueTableHeader>
            )}
            {issues.map((issue) => (
              <ClosedIssueRow
                isSelected={selectedIssueId === issue.issueId}
                onClick={() => setSelected(issue.issueId)}
                key={issue.issueId}
              >
                <Line />
                <ClosedIssueDiv>
                  <IssueHeader>
                    <Icon>
                      <Image alt="" src={getStatusIcon(issue.currentRole)} />
                    </Icon>
                    {issue.contactReason}
                  </IssueHeader>
                  <LatestEvent>
                    {formatDateTime(issue.timeOfLastActivity) ||
                      "Inga händelser"}
                  </LatestEvent>
                </ClosedIssueDiv>
              </ClosedIssueRow>
            ))}
          </CardBodyBorder>
        </StyledCardComponent>
      )}
    </>
  );
};

const StyledCardComponent = styled(CardComponent)`
  border-radius: 5px;
  margin: 0;
`;

const StyledCardHeader = styled(CardHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) => props.theme.issueCardHeader.backgroundColor};
  border: none;
  padding: 8px 15px;
  border-radius: ${(props) =>
    !props.$show ? "5px" : "5px 5px 0 0"} !important;
  user-select: none;

  > svg {
    color: ${(props) => props.theme.issueCardHeader.color};
  }
`;

const StyledCardHeaderText = styled(CardHeaderText)`
  color: ${(props) => props.theme.issueCardHeader.color};
  margin: 0;
`;

const CardBodyBorder = styled.div`
  display: ${(props) => !props.$show && "none"};
  border: 1px solid #e4e4e4;
  border-top: none;
  border-radius: 0 0 5px 5px;
`;

const Line = styled.hr`
  margin: 0;
`;

const IssueTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px;
`;

const IssueRow = styled.div`
  cursor: pointer;
  background-color: ${(props) =>
    props.isSelected
      ? props.theme.issueCardRow.selected
      : props.theme.colors.white};

  &:hover {
    background-color: ${({ theme }) => theme.issueCardRow.hover};
  }

  :last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const ClosedIssueRow = styled(IssueRow)`
  background-color: ${(props) => (props.isSelected ? "#e4e4e4" : "#fff")};

  &:hover {
    background-color: #cdcdcd;
  }
`;

const IssueDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 6px;
  padding: 0.75rem 0.625rem;

  :last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  @media ${device.tablet} {
    flex-direction: row;
  }
`;

const ClosedIssueDiv = styled(IssueDiv)`
  filter: grayscale(1);
`;

const DisabledCardHeader = styled(CardHeader)`
  background-color: #e4e4e4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: none;
  padding: 8px 15px;
  border-radius: ${(props) =>
    !props.$show ? "5px" : "5px 5px 0 0"} !important;
  user-select: none;

  > svg {
    color: ${(props) => props.theme.colors.black};
  }
`;

const DisabledCardHeaderText = styled(CardHeaderText)`
  color: ${(props) => props.theme.colors.black};
  margin: 0;
`;

const IssueHeader = styled(H5)`
  margin-bottom: 0;
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const SmallText = styled.span`
  font-size: small;
`;

const LatestEvent = styled(SmallText)`
  min-width: 95px;
  margin-top: 2px;
`;

const Icon = styled.span`
  margin-right: 3px;
`;

const Image = styled.img`
  width: 25px;
`;

export default PatientIssues;
