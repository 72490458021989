import { defaultTheme as defaultComponentLibraryTheme } from "@collabodoc/component-library";
import logo from "../Images/logo/collabodoc_light.png";

const defaultTheme = {
  ...defaultComponentLibraryTheme,
  logo: logo,
  card: {
    cardHeader: {
      backgroundColor: defaultComponentLibraryTheme.colors.primary,
      color: "white",
    },
    selectedCard: {
      backgroundColor: defaultComponentLibraryTheme.colors.tertiary,
      color: defaultComponentLibraryTheme.colors.white,
      borderColor: defaultComponentLibraryTheme.colors.secondary,
    },
    message: {
      selfBackground: defaultComponentLibraryTheme.colors.secondary,
      otherBackground: defaultComponentLibraryTheme.colors.disabled,
    },
    hover: {
      backgroundColor: defaultComponentLibraryTheme.colors.secondary,
    },
  },
  filter: {
    backgroundColor: defaultComponentLibraryTheme.colors.tertiary,
    color: defaultComponentLibraryTheme.colors.primary,
  },
  pagination: {
    active: defaultComponentLibraryTheme.colors.focus,
    dark: defaultComponentLibraryTheme.colors.primary,
    light: defaultComponentLibraryTheme.colors.white,
  },
  notification: {
    unread: defaultComponentLibraryTheme.colors.tertiary,
  },
  dropdown: {
    groupHeaderColor: defaultComponentLibraryTheme.colors.secondary,
  },
  issueCardHeader: {
    backgroundColor: defaultComponentLibraryTheme.colors.secondary,
    color: defaultComponentLibraryTheme.colors.primary,
  },
  issueCardRow: {
    selected: defaultComponentLibraryTheme.colors.tertiary,
    hover: defaultComponentLibraryTheme.colors.secondary,
  },
};

export default defaultTheme;
