import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  CheckboxWithLabel,
  doFetch,
  DropDown,
  getRequestInit,
  H2,
  SecondaryButton,
  SuccessButton,
  TextInputWithLabelAndError,
} from "@collabodoc/component-library";
import Personnummer from "personnummer";
import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { MedicalRoundContext } from "../../context/MedicalRoundContext";
import { API_URLS } from "../../enums/Urls";
import { sortAscendingByKey } from "../../functions/sortFunctions";

export const EditPatientModal = ({
  patient,
  showModal,
  hideModal,
  refetch,
}) => {
  const [personId, setPersonId] = useState(patient.personId);
  const [firstName, setFirstName] = useState(patient.firstName);
  const [lastName, setLastName] = useState(patient.lastName);
  const [careCenterId, setCareCenterId] = useState(
    patient.unit && patient.unit.careCenterId
  );
  const [unitId, setUnitId] = useState(patient.unit && patient.unit.unitId);
  const [isListed, setIsListed] = useState(patient.isListed);
  const [hasPersonalNumber, setHasPersonalNumber] = useState(
    patient.hasPersonalNumber
  );

  const [pnrLengthError, setShowPnrLengthError] = useState(false);
  const [updateError, setShowUpdateError] = useState(false);
  const [pnrValidityError, setShowPnrValidityError] = useState(false);
  const [patientExistsError, setShowPatientExistsError] = useState(false);

  const { accessToken } = useOidcAccessToken();
  const { careCenters = [] } = useContext(MedicalRoundContext);

  const checkPersonalNumber = (pnr, hasPnr) => {
    setPersonId(pnr);
    let pnrWithoutHyphen = pnr.replace("-", "");
    if (!pnr) {
      return;
    }
    if (hasPnr) {
      if (pnrWithoutHyphen.length !== 12) {
        setShowPatientExistsError(false);
        setShowPnrValidityError(false);
        setShowPnrLengthError(true);
      } else {
        setShowPatientExistsError(false);
        setShowPnrLengthError(false);
        if (Personnummer.valid(pnr, { allowCoordinationNumber: false })) {
          const init = getRequestInit({ accessToken });
          init.headers.personId = pnr;
          init.headers.hasPersonalNumber = true;
          doFetch(API_URLS.PATIENT_BY_PERSON_ID, init).then(({ data }) => {
            if (data && data.id !== patient.id) {
              setShowPatientExistsError(true);
            }
          });
        } else {
          setShowPnrValidityError(true);
        }
      }
    } else {
      if (pnr) {
        const init = getRequestInit({ accessToken });
        init.headers.personId = pnrWithoutHyphen;
        init.headers.hasPersonalNumber = Personnummer.valid(pnrWithoutHyphen, {
          allowCoordinationNumber: false,
        });
        doFetch(API_URLS.PATIENT_BY_PERSON_ID, init).then(({ data }) => {
          if (data && data.id !== patient.id) {
            setShowPatientExistsError(true);
          } else {
            setShowPatientExistsError(false);
          }
        });
      }
    }
  };

  const toggleHasPersonalNumber = (hasPnr) => {
    setHasPersonalNumber(!hasPersonalNumber);
    setShowPnrLengthError(false);
    setShowPnrValidityError(false);
    setShowPatientExistsError(false);
    if (personId && personId !== "19") {
      checkPersonalNumber(personId, hasPnr);
    } else {
      if (hasPnr) {
        setPersonId("19");
      } else {
        setPersonId("");
      }
    }
  };

  const handleSave = async () => {
    setShowUpdateError(false);
    const data = {
      unitId: unitId,
      personId: personId,
      firstname: firstName,
      lastname: lastName,
      listed: isListed,
      hasPersonalNumber: hasPersonalNumber,
    };
    await doFetch(
      API_URLS.PATIENT_EDIT(patient.id),
      getRequestInit({ accessToken, method: "PUT", data })
    ).then(({ response }) => {
      if (response.ok) {
        hideModal();
        refetch();
      } else {
        setShowUpdateError(true);
      }
    });
  };

  const reset = () => {
    setPersonId(patient.personId);
    setFirstName(patient.firstName);
    setLastName(patient.lastName);
    setCareCenterId(patient.unit.careCenterId);
    setUnitId(patient.unit.unitId);
    setIsListed(patient.isListed);
    setHasPersonalNumber(patient.hasPersonalNumber);
    setShowPatientExistsError(false);
    setShowPnrLengthError(false);
    setShowPnrValidityError(false);
    setShowUpdateError(false);

    hideModal();
  };

  const sortedCareCenters =
    careCenters &&
    sortAscendingByKey(
      careCenters.filter((careCenter) => careCenter.units.length),
      "name"
    );
  const selectedCareCenter =
    sortedCareCenters &&
    careCenterId &&
    sortedCareCenters.find((x) => x.careCenterId === careCenterId);
  const sortedSelectedCareCenters =
    selectedCareCenter && sortAscendingByKey(selectedCareCenter.units, "name");
  const selectedUnit =
    selectedCareCenter &&
    selectedCareCenter.units.find((x) => x.unitId === unitId);

  let disabled =
    !personId ||
    !firstName ||
    !lastName ||
    !careCenterId ||
    !unitId ||
    patientExistsError ||
    pnrLengthError ||
    pnrValidityError;

  let personIdText = hasPersonalNumber ? "personnummer" : "id";
  return (
    <Modal show={showModal} onHide={() => reset()}>
      <Modal.Header>
        <H2>Redigera patient</H2>
      </Modal.Header>
      <Modal.Body>
        <ModalInputDiv>
          <ModalInputLabel>
            {hasPersonalNumber
              ? "Personnummer"
              : "Reservnummer / samordningsnummer / födelsenummer"}
          </ModalInputLabel>
          <TextInputWithLabelAndError
            maxLength={20}
            placeholder={
              hasPersonalNumber
                ? "ÅÅÅÅMMDD-XXXX"
                : "Reservnummer / samordningsnummer / födelsenummer"
            }
            value={personId}
            handleChange={(e) => checkPersonalNumber(e, hasPersonalNumber)}
          />
          {pnrLengthError && (
            <InputError>
              Ange personnummer med 12 siffror (ÅÅÅÅMMDD-XXXX)
            </InputError>
          )}
          {pnrValidityError && <InputError>Ogiltigt personnummer</InputError>}
          {patientExistsError && (
            <InputErrorRed>
              En patient med detta {personIdText} finns redan
            </InputErrorRed>
          )}
          <CheckboxWithLabel
            checked={!hasPersonalNumber}
            label={"Personnummer saknas"}
            handleChange={() => toggleHasPersonalNumber(!hasPersonalNumber)}
          />
        </ModalInputDiv>
        <>
          <ModalInputDiv>
            <ModalInputLabel>Förnamn</ModalInputLabel>
            <TextInputWithLabelAndError
              value={firstName}
              handleChange={(e) => setFirstName(e)}
            />
          </ModalInputDiv>
          <ModalInputDiv>
            <ModalInputLabel>Efternamn</ModalInputLabel>
            <TextInputWithLabelAndError
              value={lastName}
              handleChange={(e) => setLastName(e)}
            />
          </ModalInputDiv>
          <ModalInputDiv>
            <ModalInputLabel>Boende</ModalInputLabel>
            <DropDown
              placeholder={"Välj boende"}
              options={
                sortedCareCenters &&
                sortedCareCenters.map((x) => ({
                  text: x.name,
                  id: x.careCenterId,
                }))
              }
              handler={(id) => {
                setCareCenterId(id);
                setUnitId(null);
              }}
              sort={true}
              selected={selectedCareCenter && selectedCareCenter.name}
            />
          </ModalInputDiv>
          <ModalInputDiv>
            <ModalInputLabel>Avdelning</ModalInputLabel>
            <DropDown
              placeholder={"Välj avdelning"}
              options={
                sortedSelectedCareCenters &&
                sortedSelectedCareCenters.map((unit) => ({
                  text: unit.name,
                  id: unit.unitId,
                }))
              }
              handler={(id) => setUnitId(id)}
              selected={selectedUnit && selectedUnit.name}
            />
          </ModalInputDiv>
          <ModalInputDiv>
            <CheckboxWithLabel
              label={"Patienten är listad"}
              checked={isListed}
              handleChange={() => setIsListed(!isListed)}
            />
          </ModalInputDiv>
        </>
        {updateError && (
          <InputErrorRed>
            Patientinformationen kunde inte uppdateras
          </InputErrorRed>
        )}
      </Modal.Body>
      <Footer>
        <SecondaryButton onClick={() => reset()}>Avbryt</SecondaryButton>
        <SuccessButton disabled={disabled} onClick={() => handleSave()}>
          Spara
        </SuccessButton>
      </Footer>
    </Modal>
  );
};

const Footer = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between;
`;

const ModalInputLabel = styled.strong`
  font-style: bold;
`;

const ModalInputDiv = styled.div`
  margin-bottom: 10px;
`;

const InputError = styled.i`
  font-size: 14px;
`;

const InputErrorRed = styled(InputError)`
  color: red;
`;
