import {faEllipsisH} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Dropdown} from "react-bootstrap";
import useOnclickOutside from "react-cool-onclickoutside";
import styled from "styled-components";

const DropDownMenu = ({ showMenu, toggleMenu, handleRowClick, setDropDownOpen }) => {
    const handleClickOutside = useOnclickOutside(() => {
        setDropDownOpen(false);
    });
    
    return (
        <>
            <Dropdown show={showMenu} onClick={(e) => toggleMenu(e)}>
                <DropdownToggle><Icon icon={faEllipsisH} /></DropdownToggle>
                <Dropdown.Menu ref={handleClickOutside}>
                    <DropdownItem onClick={handleRowClick}>
                        Öppna
                    </DropdownItem>
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
}

const DropdownItem = styled(Dropdown.Item)`
    background-color: white;
    color:  ${({ theme }) => theme.colors.primary};
    :hover {
        background-color:  ${({ theme }) => theme.colors.secondary};
        color:  ${({ theme }) => theme.colors.primary};
    }
`;

const DropdownToggle = styled(Dropdown.Toggle)`   
    background: none;
    border: none;
    display: block;
    font-size: 16px;
    line-height: 16px;
    margin: 0 16px;
    padding: 3px;
    text-align: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    
    :not(:disabled):not(.disabled):active,
    :not(:disabled):not(.disabled):active:focus,
    .dropdown-toggle:focus,
    .show > &.dropdown-toggle,
    .show > &.dropdown-toggle&:focus,
    :focus,
    :hover {
        background: none;
        box-shadow: none;
        border: 1px solid ${({ theme }) => theme.colors.primary};
    }
    :after{
        display: none;
    }
`;

const Icon = styled(FontAwesomeIcon)`
color:  ${({ theme }) => theme.colors.primary};
`;


export default DropDownMenu;
