import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  doFetch,
  getRequestInit,
  H3,
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from "@collabodoc/component-library";
import { faDownload, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { device } from "../../Content/Style/devices";
import { API_URLS } from "../../enums/Urls";

const Attachment = ({ patientId, issueId, file }) => {
  const [fileUrl, setFile] = useState(null);
  const [show, setShow] = useState(false);
  const { accessToken } = useOidcAccessToken();
  const url = API_URLS.OPEN_FILE(patientId, issueId, file.fileId);
  const init = getRequestInit({ accessToken });
  const showFile = () => {
    doFetch(url, init).then(({ data: blob }) => {
      const fileUrl = window.URL.createObjectURL(blob);
      // setTimeOut needed to open pdf files in ios browser bc window.open is not allowed in async function
      setTimeout(() => {
        setFile(fileUrl);
        if (blob.type === "application/pdf") {
          window.open(fileUrl, "_blank");
        } else {
          setShow(true);
        }
      });
    });
  };

  const downloadFile = () => {
    doFetch(url, init).then(({ data: blob }) => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = file.fileName;
      link.click();
    });
  };

  return (
    <>
      <FileWrapper>
        <FileDescription onClick={() => showFile()}>
          <FontAwesomeIcon icon={faPaperclip} />
          {file.fileName}
        </FileDescription>
      </FileWrapper>
      <ImageModal show={show} onHide={() => setShow(false)}>
        <Modal.Header>
          <FileName>{file.fileName}</FileName>
        </Modal.Header>
        <Modal.Body>
          <AttachmentImage
            src={fileUrl}
            alt={"attached image"}
          ></AttachmentImage>
        </Modal.Body>
        <Footer>
          <SecondaryButton onClick={() => setShow(false)}>
            Stäng
          </SecondaryButton>
          <PrimaryButton onClick={() => downloadFile()}>
            {" "}
            <Icon icon={faDownload} />
            Ladda ner
          </PrimaryButton>
        </Footer>
      </ImageModal>
    </>
  );
};

const ImageModal = styled(Modal)`
  .modal-dialog {
    max-width: fit-content;
    margin: 1.75rem auto;
  }
`;

const AttachmentImage = styled.img`
  max-height: 60vh;
  @media ${device.laptopS} {
    max-width: 40vh;
  }
`;

const FileWrapper = styled.div`
  font-size: 14px;
  padding-right: 10px;
  display: flex;
`;

const FileDescription = styled(TextButton)`
  display: flex;
  align-items: center;
  gap: 5px;
  padding-left: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FileName = styled(H3)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-top: 4px;
  margin-right: 4px;
`;

const Footer = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between;
`;

export default Attachment;
