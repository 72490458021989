import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  DangerButton,
  doFetch,
  getRequestInit,
  H2,
  SecondaryButton,
  SuccessButton,
} from "@collabodoc/component-library";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { device, size } from "../../Content/Style/devices";
import { ISSUE_STATUS } from "../../enums/enums";
import { API_URLS } from "../../enums/Urls";

export const CloseIssueButton = ({ issue, currentUser }) => {
  const { accessToken } = useOidcAccessToken();
  const [showModal, setShowModal] = useState(false);
  const isDisabled = currentUser && currentUser === issue.latestMessageSender;
  const isBetweenTabletAndLaptopS = useMediaQuery({
    query: `(min-width: ${size.tablet}) and (max-width: ${size.laptopS})`,
  });
  const isMobileM = useMediaQuery({ query: device.mobileM });

  const unansweredMeasures = issue?.content?.some((c) => {
    return c.isCompleted === false;
  });

  const handleYesClick = () => {
    const url = API_URLS.PATIENT_ISSUE_STATUS(
      issue && issue.patientId,
      issue.issueId
    );
    const init = getRequestInit({
      accessToken,
      method: "PATCH",
      data: { status: ISSUE_STATUS.COMPLETED },
    });
    doFetch(url, init).then(() => {
      setShowModal(false);
    });
  };

  return (
    issue && (
      <>
        <DangerButton disabled={isDisabled} onClick={() => setShowModal(true)}>
          Avsluta{!isBetweenTabletAndLaptopS && !isMobileM && " ärende"}
        </DangerButton>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header>
            <H2>Avsluta ärende</H2>
          </Modal.Header>
          <Modal.Body>
            {unansweredMeasures ? (
              <TextWrapper>
                <Icon size={"4x"} icon={faTriangleExclamation} />
                Det finns fortfarande obesvarade åtgärder på ärendet.
                <br />
                Är du säker på att du vill avsluta ärendet?
              </TextWrapper>
            ) : (
              <p>Är du säker på att du vill avsluta ärendet?</p>
            )}
          </Modal.Body>
          <ButtonDiv>
            <SecondaryButton onClick={() => setShowModal(false)}>
              Nej, avbryt
            </SecondaryButton>
            <SuccessButton onClick={handleYesClick}>Ja, avsluta</SuccessButton>
          </ButtonDiv>
        </Modal>
      </>
    )
  );
};

const TextWrapper = styled.span`
  margin-left: 5px;
  display: flex;
  align-items: center;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.danger};
  margin-right: 10px;
`;

const ButtonDiv = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between;
`;
