import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  getRequestInit,
  LoadingView,
  useFetchy,
  getClinicRole,
  SecondaryButton,
} from "@collabodoc/component-library";
import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import {
  CardBody,
  CardComponent,
  CardHeader,
  CardHeaderText,
} from "../../Content/Style/cardstyles";
import { device, size } from "../../Content/Style/devices";
import { MedicalRoundContext } from "../../context/MedicalRoundContext";
import { ACTIVE_TAB, USER_ROLES } from "../../enums/enums";
import { API_URLS } from "../../enums/Urls";
import { usePatientIssuesSubscription } from "../../hooks/usePatientIssuesSubscription";
import { Issue } from "./Issue";
import { PatientInfo } from "./PatientInfo";
import PatientIssues from "./PatientIssues";
import { sortAscendingByKey } from "../../functions/sortFunctions";

export const IssueIdKey = "issueId";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #f1f1f1;
  }
`;

const PatientView = () => {
  const location = useLocation();
  const { patientId } = useParams();
  const { accessToken } = useOidcAccessToken();
  const { contactReasons, oidcUser } = useContext(MedicalRoundContext);
  const clinicRole = getClinicRole(oidcUser?.role);
  const [isNewIssue, setIsNewIssue] = useState(location.state?.isNewIssue);
  const [searchParams, setSearchParams] = useSearchParams();
  const [reminderModalOpen, setReminderModalOpen] = useState(
    location.state?.reminderModalOpen
  );
  const { issues } = usePatientIssuesSubscription(patientId);
  const { doFetch: refetchPatient, data: patient } = useFetchy(
    API_URLS.PATIENT(patientId),
    getRequestInit({ accessToken })
  );

  const [activeTab, setActiveTab] = useState(
    isNewIssue ? ACTIVE_TAB.NEW_ISSUE_TAB : ACTIVE_TAB.SELECTED_ISSUE_TAB
  );

  const isTablet = useMediaQuery({ query: device.tablet });

  const firstIssueId = issues[0]?.issueId;
  const selectedIssueId = Number(searchParams.get(IssueIdKey));
  const selectedIssue = issues.find(
    (issue) => issue.issueId === selectedIssueId
  );

  const handleSelectIssue = (issueId) => {
    searchParams.set(IssueIdKey, issueId);
    setSearchParams(searchParams, { replace: true });
    setIsNewIssue(false);
    isTablet && setActiveTab(ACTIVE_TAB.SELECTED_ISSUE_TAB);
  };

  useEffect(() => {
    if (isNewIssue && !isTablet) {
      searchParams.delete(IssueIdKey);
      setSearchParams(searchParams, { replace: true });
    } else if (!searchParams.get(IssueIdKey) && firstIssueId) {
      searchParams.set(IssueIdKey, firstIssueId);
      setSearchParams(searchParams, { replace: true });
    }
  }, [isNewIssue, searchParams, setSearchParams, firstIssueId]);

  const handleSelectNewIssueTab = () => {
    setIsNewIssue(true);
    setActiveTab(ACTIVE_TAB.NEW_ISSUE_TAB);
  };

  const roleHasContactReasons = contactReasons.some(
    (contactReason) => contactReason.fromRole === USER_ROLES[clinicRole]
  );

  if (!patient || !issues || !oidcUser) {
    return <LoadingView />;
  }

  return (
    <>
      <PatientViewWrapper>
        <GlobalStyle />
        <PatientInfo
          patient={patient}
          refetchPatient={refetchPatient}
          reminderModalOpen={reminderModalOpen}
          setReminderModalOpen={setReminderModalOpen}
        />
        {!isTablet && (
          <IssueWrapper>
            <StyledCardComponent>
              <CardHeader>
                <CardHeaderText>Ärenden</CardHeaderText>
              </CardHeader>
              <StyledCardBody>
                {roleHasContactReasons && !isTablet && (
                  <SecondaryButton onClick={() => setIsNewIssue(true)}>
                    Nytt ärende
                  </SecondaryButton>
                )}
                <PatientIssues
                  issues={issues}
                  setSelected={handleSelectIssue}
                  selectedIssueId={selectedIssueId}
                />
              </StyledCardBody>
            </StyledCardComponent>

            <Issue
              issue={!isNewIssue && selectedIssue}
              handleIsSelectedIssueSection={handleSelectIssue}
            />
          </IssueWrapper>
        )}
        {isTablet && (
          <StyledCardComponent>
            <IssueNavigationButtons>
              <StyledCardHeader
                onClick={() => setActiveTab(ACTIVE_TAB.ALL_ISSUES_TAB)}
                $isSelected={activeTab === ACTIVE_TAB.ALL_ISSUES_TAB}
              >
                <StyledCardHeaderText>Alla ärenden</StyledCardHeaderText>
              </StyledCardHeader>
              {issues.length > 0 && (
                <StyledCardHeader
                  onClick={() => setActiveTab(ACTIVE_TAB.SELECTED_ISSUE_TAB)}
                  $isSelected={activeTab === ACTIVE_TAB.SELECTED_ISSUE_TAB}
                >
                  <StyledCardHeaderText>
                    {selectedIssue
                      ? selectedIssue.contactReason
                      : "Valt ärende"}
                  </StyledCardHeaderText>
                </StyledCardHeader>
              )}
              {roleHasContactReasons && (
                <StyledCardHeader
                  onClick={handleSelectNewIssueTab}
                  $isSelected={activeTab === ACTIVE_TAB.NEW_ISSUE_TAB}
                >
                  <StyledCardHeaderText>Nytt ärende</StyledCardHeaderText>
                </StyledCardHeader>
              )}
            </IssueNavigationButtons>

            <IssueWrapper>
              {activeTab === ACTIVE_TAB.ALL_ISSUES_TAB && (
                <PatientIssues
                  issues={issues}
                  setSelected={handleSelectIssue}
                  selectedIssueId={selectedIssueId}
                />
              )}
              {activeTab === ACTIVE_TAB.SELECTED_ISSUE_TAB && (
                <Issue
                  issue={selectedIssue}
                  handleIsSelectedIssueSection={handleSelectIssue}
                />
              )}
              {activeTab === ACTIVE_TAB.NEW_ISSUE_TAB && (
                <Issue
                  issue={!isNewIssue && selectedIssue}
                  handleIsSelectedIssueSection={handleSelectIssue}
                />
              )}
            </IssueWrapper>
          </StyledCardComponent>
        )}
      </PatientViewWrapper>
    </>
  );
};

const StyledCardComponent = styled(CardComponent)`
  height: 83vh;
  margin-bottom: 0;
  overflow: hidden;

  @media (min-width: ${size.laptopM}) {
    flex: 2;
  }

  @media (min-width: ${size.tablet}) and (max-width: ${size.laptopM}) {
    flex: 3;
  }
`;

const StyledCardBody = styled(CardBody)`
  padding: 0.75rem 0.5rem;
  background-color: #fff;
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 12px;
`;

const IssueNavigationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  background-color: #f1f1f1;
`;

const StyledCardHeader = styled(CardHeader)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px 15px 0 0;
  padding: 12px 15px;
  flex: 1;
  text-align: center;
  filter: ${({ $isSelected }) => !$isSelected && "opacity(60%)"};
`;

const StyledCardHeaderText = styled(CardHeaderText)`
  margin: 0;

  @media ${device.tablet} {
    word-break: break-word;
    font-size: 14px;
  }
`;

const IssueWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 12px;

  @media ${device.tablet} {
    flex: 1;
    overflow-y: auto;
    flex-direction: column;
    padding: 18px;
  }
`;

const PatientViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 120px;
  margin-right: 120px;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-top: 12px;

  @media ${device.desktop} {
    margin-left: 0;
    margin-right: 0;
  }
`;

export default PatientView;
