import { CheckboxWithLabel } from "@collabodoc/component-library";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import styled from "styled-components";
import { sortAscending } from "../../functions/sortFunctions";
import usePatientListFilters from "../../hooks/usePatientListFilters";
import { FilterHeader, FilterOption, Header } from "./SingleFilter";

export const MultiFilter = ({
  showFilterDefault = true,
  showAllOption = false,
  filterHeader,
  name,
  options,
  showHeadersByProperty,
}) => {
  const [showFilter, setShowFilter] = useState(showFilterDefault);
  const {
    areAllSelected,
    toggleAllSelected,
    isFilterSelected,
    toggleFilterSelected,
  } = usePatientListFilters();

  const groups = options.reduce((acc, obj) => {
    const key = obj[showHeadersByProperty];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});

  return (
    <>
      <Header onClick={() => setShowFilter(!showFilter)}>
        <FilterHeader>{filterHeader}</FilterHeader>
        <FontAwesomeIcon icon={showFilter ? faChevronUp : faChevronDown} />
      </Header>
      <FilterOption hidden={!showFilter}>
        {showAllOption && (
          <>
            <CheckboxWithLabel
              label={"Alla"}
              checked={areAllSelected(name, options)}
              handleChange={() => toggleAllSelected(name, options)}
            />
            <Hr />
          </>
        )}
        {Object.entries(groups)
          .sort(([a], [b]) => sortAscending(a, b))
          .map(([groupName, groupOptions], index) => (
            <fieldset key={index} name={name}>
              {showHeadersByProperty && <Legend>{groupName}</Legend>}
              {groupOptions.map((option) => (
                <CheckboxWithLabel
                  key={option.id}
                  label={option.text}
                  value={option.id}
                  checked={isFilterSelected(name, option.id)}
                  handleChange={() => toggleFilterSelected(name, option.id)}
                />
              ))}
            </fieldset>
          ))}
      </FilterOption>
    </>
  );
};

const Hr = styled.hr`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;
export const Legend = styled.legend`
  margin-bottom: 0;
  font-size: 16px;
  font-weight: bold;
  display: block;
  position: relative;
  user-select: none;
`;
