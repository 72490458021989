import {
  B,
  getClinicRole,
  P,
  PrimaryButton,
  SecondaryButton,
} from "@collabodoc/component-library";
import { faNoteSticky } from "@fortawesome/free-regular-svg-icons";
import {
  faCalendar,
  faChevronLeft,
  faNoteSticky as faNoteStickySolid,
  faUserPen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { device } from "../../Content/Style/devices";
import { EditPatientModal } from "./EditPatientModal";
import { NoteModal } from "./NoteModal";
import ReminderModal from "./ReminderModal";
import { useOidcUser } from "@axa-fr/react-oidc";

export const PatientInfo = ({
  patient,
  refetchPatient,
  reminderModalOpen,
  setReminderModalOpen,
}) => {
  const navigate = useNavigate();
  const [editPatientModalOpen, setEditPatientModalOpen] = useState(false);
  const [notePatientModalOpen, setNotePatientModalOpen] = useState(false);
  const [showReminderIndicator, setShowReminderIndicator] = useState(false);

  const { oidcUser } = useOidcUser();
  const clinicRole = getClinicRole(oidcUser?.role);

  useEffect(() => {
    const lastMidnight = new Date().setHours(0, 0, 0, 0);

    const currentReminders =
      patient.reminders.filter(
        (reminder) =>
          new Date(reminder.date).setHours(0, 0, 0, 0) <= lastMidnight
      ).length > 0;

    setShowReminderIndicator(currentReminders);
  }, [patient]);

  const unit =
    patient &&
    patient.unit &&
    `${patient.unit.careCenterName} / ${patient.unit.name} /\u00A0`;
  const patientName =
    patient &&
    `${patient.firstName} ${patient.lastName} ${patient.personId}\u00A0`;

  const NoteButton = patient?.note?.text ? PrimaryButton : SecondaryButton;
  const NoteIcon = patient?.note?.text ? faNoteStickySolid : faNoteSticky;

  const isLaptopS = useMediaQuery({ query: device.laptopS });
  const isLaptopM = useMediaQuery({ query: device.laptopM });

  return (
    <>
      <PatientInfoWrapper>
        <LeftContent>
          <SecondaryButton onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            {!isLaptopM && "Tillbaka"}
          </SecondaryButton>
          <PatientPropertyWrapper>
            {!isLaptopS && <PatientProperty>{unit}</PatientProperty>}
            <PatientPropertyBold>{patientName}</PatientPropertyBold>
            {!isLaptopS && (
              <PatientProperty>
                {patient.isListed ? "(Listad)" : "(Ej listad)"}
              </PatientProperty>
            )}
          </PatientPropertyWrapper>
        </LeftContent>

        <RightContent>
          <NoteButton onClick={() => setNotePatientModalOpen(true)}>
            <FontAwesomeIcon icon={NoteIcon} />
            {!isLaptopM && " Privat anteckning"}
          </NoteButton>
          {clinicRole && (
            <NotificationIconWrapper>
              <SecondaryButton onClick={() => setReminderModalOpen(true)}>
                <FontAwesomeIcon icon={faCalendar} />{" "}
                {!isLaptopM && "Påminnelser"}
              </SecondaryButton>
              <NotificationIndicator
                showReminderIndicator={showReminderIndicator}
              />
            </NotificationIconWrapper>
          )}
          <SecondaryButton onClick={() => setEditPatientModalOpen(true)}>
            <FontAwesomeIcon icon={faUserPen} />{" "}
            {!isLaptopM && "Redigera patient"}
          </SecondaryButton>
        </RightContent>
      </PatientInfoWrapper>
      <EditPatientModal
        patient={patient}
        showModal={editPatientModalOpen}
        hideModal={() => setEditPatientModalOpen()}
        refetch={refetchPatient}
      />
      <NoteModal
        patientId={patient.id}
        noteText={patient?.note?.text}
        showModal={notePatientModalOpen}
        hideModal={() => setNotePatientModalOpen(false)}
        reFetchPatient={refetchPatient}
      />
      <ReminderModal
        showModal={reminderModalOpen}
        setShowModal={setReminderModalOpen}
        patient={patient}
        refetchPatient={refetchPatient}
      />
    </>
  );
};

const LeftContent = styled.div`
  display: flex;
`;

const RightContent = styled.div`
  display: flex;
  gap: 10px;
`;

const PatientInfoWrapper = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > button:first-of-type {
    white-space: nowrap;

    > svg {
      margin-right: 4px;
    }
  }

  @media ${device.tablet} {
    gap: 10px;
  }
`;

const PatientPropertyWrapper = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  align-items: center;
  display: flex;

  @media ${device.tablet} {
    margin-left: 5px;
  }
`;

const PatientProperty = styled(P)`
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.primary};

  @media ${device.mobileL} {
    font-size: 14px;
  }
`;

const PatientPropertyBold = styled(B)`
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.primary};

  @media ${device.mobileL} {
    font-size: 14px;
  }
`;

const NotificationIconWrapper = styled.div`
  position: relative;
`;

const NotificationIndicator = styled.div`
  display: ${({ showReminderIndicator }) => !showReminderIndicator && "none"};
  position: absolute;
  top: -5px;
  right: 5px;
  padding: 5px;
  background-color: #ff3030;
  border-radius: 50%;
  border: 1px solid #0d3050;
`;
