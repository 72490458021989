/* eslint-disable react-hooks/exhaustive-deps */
import { SecondaryButton } from "@collabodoc/component-library";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { device } from "../../Content/Style/devices";
import { MedicalRoundContext } from "../../context/MedicalRoundContext";
import {
  FILTER_KEYS,
  STATUS_FILTER_NAME,
  USER_ROLE_NAMES,
} from "../../enums/enums";
import { sortAscendingByKey } from "../../functions/sortFunctions";
import usePatientListFilters from "../../hooks/usePatientListFilters";
import { MultiFilter } from "./MultiFilter";

export const SideMenu = ({ setShowSideMenu, showSideMenu }) => {
  const [transitionEnded, setTransitionEnded] = useState(false);
  const { careCenters, contactReasons } = useContext(MedicalRoundContext);
  const isTablet = useMediaQuery({ query: device.tablet });
  const sortedCareCenters = sortAscendingByKey(
    careCenters.filter((careCenter) => careCenter.units.length),
    "name"
  );
  const careCenterOptions = sortedCareCenters.map(
    ({ careCenterId: id, name: text }) => ({ id, text })
  );

  const { isFilterSelected, getFilterValues } = usePatientListFilters();
  const currentCareCenterIds = getFilterValues(FILTER_KEYS.CARE_CENTER_ID);
  const unitsOptions = sortedCareCenters
    .filter(
      ({ careCenterId }) =>
        isFilterSelected(FILTER_KEYS.CARE_CENTER_ID, careCenterId) ||
        !currentCareCenterIds.length
    )
    .flatMap(({ units }) =>
      sortAscendingByKey(units, "name").map(({ unitId: id, name: text }) => ({
        id,
        text,
      }))
    );
  const showOnlyPatientsWithNoteOptions = [
    { id: true, text: "Patienter med privat anteckning" },
  ];
  const roleOptions = Object.entries(STATUS_FILTER_NAME).map(
    ([key, value]) => ({ text: value, id: key })
  );
  const contactReasonOptions = contactReasons.map((c) => ({
    text: c.name,
    id: c.id,
    direction: `${USER_ROLE_NAMES[c.fromRole]} till ${
      USER_ROLE_NAMES[c.toRole]
    }`,
  }));

  return (
    <FilterContainer
      showSideMenu={showSideMenu}
      isTablet={isTablet}
      transitionEnded={transitionEnded}
      onTransitionEnd={() => setTransitionEnded(!transitionEnded)}
    >
      <MultiFilter
        filterHeader={"Boende"}
        name={FILTER_KEYS.CARE_CENTER_ID}
        options={careCenterOptions}
        showAllOption={careCenterOptions.length > 3}
        keysToDelete={[FILTER_KEYS.PAGE, FILTER_KEYS.UNIT_ID]}
      />
      <MultiFilter
        filterHeader={"Avdelning"}
        name={FILTER_KEYS.UNIT_ID}
        options={unitsOptions}
        showAllOption={unitsOptions.length > 3}
        showFilterDefault={currentCareCenterIds.length > 0}
      />

      <MultiFilter
        filterHeader={"Status"}
        name={FILTER_KEYS.STATUS}
        options={roleOptions}
      />

      <MultiFilter
        filterHeader={"Privat anteckning"}
        name={FILTER_KEYS.ONLY_WITH_NOTES}
        options={showOnlyPatientsWithNoteOptions}
      />
      <MultiFilter
        filterHeader={"Kontaktorsak"}
        name={FILTER_KEYS.CONTACT_REASON}
        options={contactReasonOptions}
        showFilterDefault={false}
        showAllOption={true}
        showHeadersByProperty={"direction"}
      />
      {isTablet && (
        <ButtonWrapper>
          <SecondaryButton onClick={() => setShowSideMenu(false)}>
            <FontAwesomeIcon icon={faClose} />
            Stäng filtrering
          </SecondaryButton>
        </ButtonWrapper>
      )}
    </FilterContainer>
  );
};

const ButtonWrapper = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: flex-end;

  > button:first-of-type {
    > svg {
      margin-right: 4px;
    }
  }
`;

const FilterContainer = styled.div`
  width: ${(props) =>
    props.showSideMenu ? (props.isTablet ? "100%;" : "20%;") : "0%;"};
  background-color: white;
  border-right: 1px solid #e1e1e1;
  overflow: hidden;
  transition: width 0.5s;
  overflow-y: auto;

  > div {
    > label {
      white-space: ${(props) => (props.transitionEnded ? "normal" : "nowrap;")};
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

`;
