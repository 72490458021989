const defaultOptions: Intl.DateTimeFormatOptions = {
  weekday: "short",
  month: "short",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
};

const formatDateTime = (
  dateTimeString: string | Date | undefined,
  options: Intl.DateTimeFormatOptions | null = null
): string => {
  if (typeof dateTimeString === "undefined") {
    return "";
  }
  return Intl.DateTimeFormat("sv-SE", options || defaultOptions).format(
    new Date(dateTimeString)
  );
};

export { formatDateTime };
