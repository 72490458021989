import { useOidcUser } from "@axa-fr/react-oidc";
import { getClinicRole } from "@collabodoc/component-library";
import { useSearchParams } from "react-router-dom";
import { FILTER_KEYS, FILTERS_TO_DELETE, STATUS_FILTER } from "../enums/enums";

const deleteRelated = (searchParams, name) => {
  const keysToDelete = FILTERS_TO_DELETE[name] || [];
  keysToDelete.forEach((key) => searchParams.delete(key));
};

const usePatientListFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { oidcUser } = useOidcUser();
  const getFilterValue = (name) => searchParams.get(name);
  const getFilterValues = (name) => searchParams.getAll(name);

  const isFilterSelected = (name, value) => {
    const currentValues = searchParams.getAll(name);
    return currentValues.includes(String(value));
  };

  const areAllSelected = (name, options) => {
    const selected = getFilterValues(name);
    return options.every((option) => selected.includes(String(option.id)));
  };

  const toggleFilterSelected = (name, value) => {
    deleteRelated(searchParams, name);
    if (isFilterSelected(name, value)) {
      const newValues = getFilterValues(name).filter(
        (v) => v !== String(value)
      );
      searchParams.delete(name);
      newValues.forEach((newValue) => searchParams.append(name, newValue));
    } else {
      searchParams.append(name, value);
    }
    setSearchParams(searchParams);
  };

  const toggleAllSelected = (name, options) => {
    const allSelected = areAllSelected(name, options);
    deleteRelated(searchParams, name);
    searchParams.delete(name);
    if (!allSelected) {
      options.forEach(({ id }) => searchParams.append(name, id));
    }
    setSearchParams(searchParams);
  };

  const getDefaultFilters = () => {
    const clinicRole = getClinicRole(oidcUser?.role);
    const searchParams = new URLSearchParams();
    if (clinicRole) {
      searchParams.append(FILTER_KEYS.STATUS, clinicRole.slice(0, -4));
    } else {
      searchParams.append(FILTER_KEYS.STATUS, STATUS_FILTER.DOCTOR_USER);
      searchParams.append(FILTER_KEYS.STATUS, STATUS_FILTER.NURSE_USER);
      searchParams.append(FILTER_KEYS.STATUS, STATUS_FILTER.SECRETARY_USER);
    }
    return searchParams;
  };

  const clearFilter = (name) => {
    deleteRelated(searchParams, name);
    searchParams.delete(name);
    setSearchParams(searchParams);
  };

  return {
    getFilterValue,
    getFilterValues,
    isFilterSelected,
    areAllSelected,
    toggleFilterSelected,
    toggleAllSelected,
    clearFilter,
    getDefaultFilters,
  };
};

export default usePatientListFilters;
