import { capio as capioComponentLibraryTheme } from "@collabodoc/component-library";
import overrideLogo from "../Images/logo/Asset 3.png";
import logo from "../Images/logo/capio_white.png";

const capio = {
  ...capioComponentLibraryTheme,
  logo: logo,
  overrideLogo: overrideLogo,
  overrideLogoStyle: {
    height: "32px",
  },
  card: {
    cardHeader: {
      backgroundColor: capioComponentLibraryTheme.colors.primary,
      color: "white",
    },
    selectedCard: {
      backgroundColor: capioComponentLibraryTheme.colors.secondary,
      color: capioComponentLibraryTheme.colors.black,
      borderColor: capioComponentLibraryTheme.colors.secondary,
    },
    message: {
      selfBackground: capioComponentLibraryTheme.colors.secondary,
      otherBackground: capioComponentLibraryTheme.colors.disabled,
    },
    hover: {
      backgroundColor: capioComponentLibraryTheme.colors.tertiary,
    },
  },
  filter: {
    backgroundColor: capioComponentLibraryTheme.colors.tertiary,
    color: capioComponentLibraryTheme.colors.primary,
  },
  pagination: {
    active: capioComponentLibraryTheme.colors.focus,
    dark: capioComponentLibraryTheme.colors.primary,
    light: capioComponentLibraryTheme.colors.white,
  },
  notification: {
    unread: capioComponentLibraryTheme.colors.tertiary,
  },
  dropdown: {
    groupHeaderColor: capioComponentLibraryTheme.colors.tertiary,
  },
  issueCardHeader: {
    backgroundColor: capioComponentLibraryTheme.colors.secondary,
    color: capioComponentLibraryTheme.colors.primary,
  },
  issueCardRow: {
    selected: capioComponentLibraryTheme.colors.secondary,
    hover: capioComponentLibraryTheme.colors.secondary,
  },
};

export default capio;
