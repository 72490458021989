export interface PatientListPatientModel {
  id: string;
  personalNumber: string;
  name: string;
  firstName: string;
  lastName: string;
  isListed: boolean;
  unit: Unit;
  hasNote: boolean;
  reminders: PatientListReminderModel;
  issues: PatientListIssueModel[];
}

export interface PatientListIssueModel {
  issueId: string;
  contactReason: string;
  status: number;
  currentRole?: number;
  timeOfLastActivity: Date;
  latestMessageSender: string;
}

export interface PatientListReminderModel {
  timeOfLastActivity: Date;
  reminderCount: number;
  currentRole?: number;
}

export function isIssue(object: any): object is PatientListIssueModel {
  return (object as PatientListIssueModel).issueId !== undefined;
}

interface UserNote {
  text: string;
  timeOfUpdate: Date;
}

interface Unit {
  unitId: string;
  careCenterId: number;
  name: string;
  careCenterName?: string;
}
