import { useOidcAccessToken, useOidcUser } from "@axa-fr/react-oidc";
import {
  DangerButton,
  getRequestInit,
  H2,
  SecondaryButton,
  useDelete,
  useFetchy,
} from "@collabodoc/component-library";
import { faBell, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { MedicalRoundContext } from "../context/MedicalRoundContext";
import { API_URLS } from "../enums/Urls";
import useClickOutside from "../hooks/useClickOutside";
import { formatDateTime } from "../utils/dateUtils";

const dateTimeFormatOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
};
const Notifications = ({ show, setShow }) => {
  const { accessToken } = useOidcAccessToken();
  const { data: notifications = [], doFetch: reFetchNotifications } = useFetchy(
    API_URLS.NOTIFICATIONS,
    getRequestInit({ accessToken })
  );

  const { oidcUser } = useOidcUser();
  const [showDeleteNotificationModal, setShowDeleteNotificationModal] =
    useState({ show: false, id: null });
  const { doDelete: deleteNotification } = useDelete(
    API_URLS.DELETE_NOTIFICATION(showDeleteNotificationModal.id),
    accessToken
  );

  const { hubConnection } = useContext(MedicalRoundContext);

  useEffect(() => {
    if (hubConnection && hubConnection.state === "Connected") {
      hubConnection.on("refreshnotifications", reFetchNotifications);
    }
    return () => {
      if (hubConnection && hubConnection.state === "Connected") {
        hubConnection.off("refreshnotifications", reFetchNotifications);
      }
    };
  }, [hubConnection.state, reFetchNotifications]);

  const myRef = useClickOutside(() => {
    setShow(false);
    setShowDeleteNotificationModal(false);
    reFetchNotifications();
  });

  const handleDeleteNotification = () => {
    deleteNotification().then(() => reFetchNotifications());
  };

  const showIndicator =
    notifications.length && notifications.some((n) => !n.isRead);

  return (
    <>
      <>
        <NotificationIconWrapper>
          <FontAwesomeIcon icon={faBell} />
          <NotificationIndicator showIndicator={showIndicator} />
        </NotificationIconWrapper>
        {show &&
          createPortal(
            <NotificationModal
              notifications={notifications}
              myRef={myRef}
              oidcUser={oidcUser}
              setShowDeleteNotificationModal={setShowDeleteNotificationModal}
            />,
            document.getElementById("root")
          )}
      </>
      <Modal show={showDeleteNotificationModal.show}>
        <Modal.Header>
          <H2>Radera notis?</H2>
        </Modal.Header>
        <Modal.Body>
          <p>Vill du radera notisen?</p>
          <p>Raderade notiser kan ej återskapas.</p>
        </Modal.Body>
        <ButtonDiv>
          <SecondaryButton
            onClick={() =>
              setShowDeleteNotificationModal({ show: false, id: null })
            }
          >
            Avbryt
          </SecondaryButton>
          <DangerButton onClick={() => handleDeleteNotification()}>
            Ja, radera
          </DangerButton>
        </ButtonDiv>
      </Modal>
    </>
  );
};

const NotificationModal = ({
  notifications,
  myRef,
  oidcUser,
  setShowDeleteNotificationModal,
}) => {
  const { accessToken } = useOidcAccessToken();
  const { doFetch: setNotificationsReadTimestamp } = useFetchy(
    API_URLS.USERNOTIFICATIONREAD_NOTIFICATIONREADTIMESTAMP,
    getRequestInit({ accessToken, method: "PATCH" })
  );

  useEffect(() => {
    setNotificationsReadTimestamp();
  }, []);

  return (
    <>
      <NotificationWindow ref={myRef}>
        <StyledH2>Notiser</StyledH2>
        <NotificationBody>
          {notifications.length >= 1 ? (
            notifications.map((notification) => (
              <Notification
                key={notification.timestamp}
                read={notification.isRead}
              >
                <div>
                  <InfoText>
                    {notification.sender.name}, {notification.sender.roleName}
                  </InfoText>
                  <InfoText>
                    Till:{" "}
                    {notification.receivers.length > 1 ? (
                      <>
                        {notification.receivers[0]} {}
                        <MyTooltip>
                          + <u>{notification.receivers.length - 1} till</u>
                          <MyTooltipText>
                            {notification.receivers.slice(1).join(", ")}
                          </MyTooltipText>
                        </MyTooltip>
                      </>
                    ) : (
                      notification.receivers[0]
                    )}
                  </InfoText>
                </div>
                <NotificationMessage>
                  {notification.message}
                </NotificationMessage>
                <InfoText>
                  {formatDateTime(
                    notification.timestamp,
                    dateTimeFormatOptions
                  )}
                  {notification.sender.id === oidcUser.sub && (
                    <span
                      title={"Radera notis"}
                      onClick={() =>
                        setShowDeleteNotificationModal({
                          show: true,
                          id: notification.id,
                        })
                      }
                    >
                      <Icon icon={faTrashCan}></Icon>
                    </span>
                  )}
                </InfoText>
              </Notification>
            ))
          ) : (
            <Notification read={true}>
              <i>Inga notiser</i>
            </Notification>
          )}
        </NotificationBody>
      </NotificationWindow>
    </>
  );
};

const NotificationIconWrapper = styled.div`
  position: relative;
`;

const NotificationIndicator = styled.div`
  display: ${({ showIndicator }) => !showIndicator && "none"};
  position: absolute;
  top: 0;
  right: -4px;
  padding: 4px;
  background-color: #ff3030;
  border-radius: 50%;
  border: 1px solid #0d3050;
`;

const NotificationWindow = styled.div`
  z-index: 99;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 72px;
  right: 12px;
  margin-left: 12px;
  width: min(calc(100vw - 24px), 400px);
  max-height: 60vh;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
`;

const StyledH2 = styled(H2)`
  padding: 10px;
  margin: 0;
  border-bottom: 1px solid #c1c1c1;
`;

const NotificationMessage = styled.div`
  font-size: 1rem;
`;

const InfoText = styled.div`
  font-size: 0.75rem;
  display: flex;
  gap: 5px;
  align-items: center;
`;

const Icon = styled(FontAwesomeIcon)`
  color: #a1a1a1;
  cursor: pointer;

  &:hover {
    color: darkred;
  }
`;

const ButtonDiv = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between;
`;

const MyTooltipText = styled.span`
  visibility: hidden;
  position: absolute;
  z-index: 1;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 4px;
  border-radius: 8px;
  width: 180px;
  top: 100%;
  left: 50%;
  margin-left: -60px;
`;

const MyTooltip = styled.div`
  position: relative;
  display: inline-block;
  cursor: default;

  &:hover ${MyTooltipText} {
    visibility: visible;
  }
`;

const NotificationBody = styled.div`
  overflow-y: auto;
  border-radius: 0 0 8px 8px;
`;

const Notification = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 18px;
  gap: 8px;
  background-color: ${({ read, theme }) =>
    read ? "#f1f1f1" : theme.notification.unread};
  color: #000;
  border-bottom: 1px solid #c1c1c1;
  padding-left: 10px;

  &:last-child {
    border: none;
  }
`;

export default Notifications;
