const SITE_URLS = {
  PATIENT_LIST: "/patients",
  PATIENT_VIEW: "/patients/:patientId",
  ADMIN_VIEW: "/admin",
};

const API_URLS = {
  PATIENTS: `/api/patients`,
  PATIENT_BY_PERSON_ID: `/api/patients/getbypersonid`,
  CARE_CENTERS: `/api/organization`,
  CARE_CENTER_UNITS: (careCenterId) =>
    `/api/organization/${careCenterId}/units`,
  CARE_CENTER_UNIT: (careCenterId, unitId) =>
    `/api/organization/${careCenterId}/units/${unitId}`,
  ISSUE_SIGNALR_HUB: `/api/issuehub`,
  PATIENT: (patientId) => `/api/patients/${patientId}`,
  PATIENT_EDIT: (patientId) => `/api/patients/${patientId}`,
  PATIENT_ISSUES: (patientId) => `/api/patients/${patientId}/issues`,
  PATIENT_ISSUE_STATUS: (patientId, issueId) =>
    `/api/patients/${patientId}/issues/${issueId}/status`,
  PATIENT_ISSUE_MESSAGE: (patientId, issueId) =>
    `/api/patients/${patientId}/issues/${issueId}/message`,
  OPEN_FILE: (patientId, issueId, fileId) =>
    `/api/patients/${patientId}/issues/${issueId}/message/attachment/${fileId}`,
  MEASURE_TEMPLATES: `/api/measures/templates`,
  PATIENT_ISSUE_MEASURES: (patientId, issueId) =>
    `/api/patients/${patientId}/issues/${issueId}/measures`,
  MEASURE_COMPLETED: (patientId, issueId, measureId) =>
    `/api/patients/${patientId}/issues/${issueId}/measures/${measureId}`,
  PATIENT_NOTE_EDIT: (patientId) => `/api/patients/${patientId}/note`,
  NOTIFICATIONS: `/api/notifications`,
  USERNOTIFICATIONREAD_NOTIFICATIONREADTIMESTAMP: `/api/usernotificationread/notificationreadtimestamp`,
  DELETE_NOTIFICATION: (notificationId) =>
    `/api/notifications/${notificationId}`,
  CONTACT_REASONS: `/api/contactreasons`,
  REMINDERS: (patientId) => `/api/patients/${patientId}/reminders`,
  REMINDER: (patientId, reminderId) =>
    `/api/patients/${patientId}/reminders/${reminderId}`,
};

const EXTERNAL_URLS = {
  CAREGIVER_PORTAL_URL: `https://${window.env.careGiverPortalUrl}`,
};

export { SITE_URLS, API_URLS, EXTERNAL_URLS };
