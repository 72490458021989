const filterPatients = (patients = [], searchString = "") => {
  if (searchString) {
    return multiFilter(patients, searchString, ["name", "personalNumber"]);
  }
  return patients;
};

const multiFilter = (objects = [], searchString = "", properties = []) => {
  const searchWords = searchString.split(" ");
  return objects.filter((object) =>
    searchWords.every((searchWord) =>
      properties.some((property) =>
        object[property].toLowerCase().includes(searchWord.toLowerCase())
      )
    )
  );
};

export { filterPatients, multiFilter };
