import { useOidcUser } from "@axa-fr/react-oidc";
import {
  CopyButtonSmall,
  getClinicRole,
  P,
} from "@collabodoc/component-library";
import { useEffect, useRef } from "react";
import styled from "styled-components";
import { formatDateTime } from "../../utils/dateUtils";
import Attachment from "./Attachment";
import MeasureContent from "./MeasureContent";

const ROLES = {
  DoctorUser: 1,
  NurseUser: 2,
};

const formatMeasureCopyText = (content) => {
  if (content.text) {
    return `${content.title}\n${content.text}`;
  }
  return content.title;
};
const MessageSection = ({
  issueId,
  patientId,
  content,
  roleIsInIssue,
  isIssueClosed = false,
}) => {
  const { oidcUser } = useOidcUser();
  const ref = useRef();
  const clinicRole = getClinicRole(oidcUser?.role);

  useEffect(() => {
    ref.current &&
      ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
  }, [content]);

  return (
    <>
      {content &&
        content.map((c) => (
          <div key={c.id}>
            <MessageBox
              isClosed={isIssueClosed}
              isSender={c.createdBy.role === ROLES[clinicRole]}
              isMeasure={c.title}
            >
              <MessageWrapper>
                {!c.title && c.text && <MessageText>{c.text}</MessageText>}
                {c.title && <MessageText>Åtgärd: {c.title}</MessageText>}
                <CopyBtnWrapper>
                  <CopyButtonSmall
                    textValue={c.title ? formatMeasureCopyText(c) : c.text}
                  />
                </CopyBtnWrapper>
              </MessageWrapper>
              {c.attachments && c.attachments.length > 0 && (
                <AttachmentsWrapper>
                  {c.attachments.map((file) => (
                    <Attachment
                      key={file.fileId}
                      patientId={patientId}
                      issueId={issueId}
                      file={file}
                    />
                  ))}
                </AttachmentsWrapper>
              )}
              {c.title && (
                <MeasureContent
                  content={c}
                  issueId={issueId}
                  patientId={patientId}
                  roleIsInIssue={roleIsInIssue}
                />
              )}
            </MessageBox>
            <SenderInfo>
              <ItalicText>
                {c.createdBy.name}, {c.createdBy.roleName}
              </ItalicText>
              <ItalicText>{formatDateTime(c.timeOfCreation)}</ItalicText>
            </SenderInfo>
          </div>
        ))}
      <div ref={ref}></div>
    </>
  );
};

const AttachmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

const MessageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const MessageBox = styled.div`
  flex-direction: row;
  align-items: end;
  padding: 10px;
  border-radius: 5px;
  background-color: ${({ theme, isSender }) =>
    isSender
      ? theme.card.message.selfBackground
      : theme.card.message.otherBackground};
  filter: ${({ isClosed }) => isClosed && "grayscale(1)"};
  border-left: ${({ isMeasure, theme }) =>
    isMeasure && `8px solid ${theme.card.cardHeader.backgroundColor}`};
`;

const MessageText = styled(P)`
  overflow-wrap: anywhere;
  white-space: break-spaces;
  margin-bottom: 0;
`;

const ItalicText = styled.span`
  font-size: small;
  font-style: italic;
`;

const SenderInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2px 2px 0 2px;
`;

const CopyBtnWrapper = styled.div`
  align-self: end;
`;

export default MessageSection;
