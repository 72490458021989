import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  CheckboxWithLabel,
  doFetch,
  getRequestInit,
  H2,
  PrimaryButton,
  SecondaryButton,
  TextAreaWithLabelAndError,
} from "@collabodoc/component-library";
import { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import {
  CardBody,
  CardComponent,
  CardHeader,
  CardHeaderText,
} from "../Content/Style/cardstyles";
import { MedicalRoundContext } from "../context/MedicalRoundContext";
import { API_URLS } from "../enums/Urls";

const CreateNotification = ({ show, setShow }) => {
  const [message, setMessage] = useState("");
  const [selectedCareCenterIds, setSelectedCareCenterIds] = useState([]);
  const { accessToken } = useOidcAccessToken();
  const { careCenters } = useContext(MedicalRoundContext);
  const allCareCenterIdsSelected =
    selectedCareCenterIds.length === careCenters.length;

  const handleReceiverChange = (carecenterId) => {
    setSelectedCareCenterIds((prevState) =>
      prevState.includes(carecenterId)
        ? selectedCareCenterIds.filter((r) => r !== carecenterId)
        : [...prevState, carecenterId]
    );
  };

  const handleAllReceiversChange = () => {
    allCareCenterIdsSelected
      ? setSelectedCareCenterIds([])
      : setSelectedCareCenterIds(careCenters.map((c) => c.careCenterId));
  };

  const handleFormSubmit = () => {
    if (message !== "" && selectedCareCenterIds.length > 0) {
      doFetch(
        API_URLS.NOTIFICATIONS,
        getRequestInit({
          accessToken,
          method: "POST",
          data: {
            message: message,
            carecenterIds: selectedCareCenterIds,
          },
        })
      );
      setMessage("");
      setSelectedCareCenterIds([]);
      setShow(false);
    }
  };

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header>
        <StyledH2>Skapa notis</StyledH2>
      </Modal.Header>
      <ModalBody>
        <StyledCardComponent>
          <CardHeader>
            <CardHeaderText>Välj mottagare</CardHeaderText>
          </CardHeader>
          <StyledCardBody>
            <CheckboxWithLabel
              key={Math.random()}
              label={"Välj alla"}
              checked={allCareCenterIdsSelected}
              handleChange={() => handleAllReceiversChange()}
            />
            {careCenters.map((c) => (
              <CheckboxWithLabel
                key={c.careCenterId}
                label={c.name}
                checked={selectedCareCenterIds.includes(c.careCenterId)}
                handleChange={() => handleReceiverChange(c.careCenterId)}
              />
            ))}
          </StyledCardBody>
        </StyledCardComponent>
        <MessageWrapper>
          <TextAreaWithLabelAndError
            maxLength={300}
            label={"Meddelande"}
            minRows={4}
            value={message}
            handleChange={(e) => setMessage(e)}
          />
        </MessageWrapper>
      </ModalBody>
      <Footer>
        <SecondaryButton onClick={() => setShow(false)}>Avbryt</SecondaryButton>
        <PrimaryButton
          disabled={message === "" || selectedCareCenterIds.length < 1}
          onClick={() => handleFormSubmit()}
        >
          Skapa
        </PrimaryButton>
      </Footer>
    </Modal>
  );
};

const StyledH2 = styled(H2)`
  margin: 0;
`;

const ModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledCardComponent = styled(CardComponent)`
  flex: 1;
  margin: 0;
`;

const StyledCardBody = styled(CardBody)`
  display: flex;
  flex-direction: column;
  border: 1px solid #000;
  border-radius: 0 0 10px 10px;
  padding: 6px 12px;
  max-height: 400px;
  overflow-y: auto;

  > label {
    margin: 0;
  }
`;

const MessageWrapper = styled.div`
  flex: 1;

  > textarea {
    border-radius: 4px;
  }

  > label {
    font-weight: normal;
    margin: 0;
  }
`;

const Footer = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between;
`;

export default CreateNotification;
