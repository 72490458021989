import { MutableRefObject, useEffect, useRef } from "react";

const useClickOutside = (
  callback: Function
): MutableRefObject<HTMLElement | undefined> => {
  const myRef = useRef<HTMLElement>();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (myRef.current && !myRef.current.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return (): void => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [myRef, callback]);

  return myRef;
};

export default useClickOutside;
