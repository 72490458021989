import { useOidc, useOidcUser } from "@axa-fr/react-oidc";
import { getClinicRole, NavBar } from "@collabodoc/component-library";
import React, { useEffect, useState } from "react";
import { isMobile, isTablet } from "react-device-detect";
import { EXTERNAL_URLS } from "../enums/Urls";
import CreateNotificationModal from "./CreateNotificationModal";
import Notifications from "./Notifications";

const NavMenu = ({ themeSettings }) => {
  const { logout, isAuthenticated } = useOidc();
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);
  const { oidcUser } = useOidcUser();
  const clinicRole = getClinicRole(oidcUser?.role);

  function getPWADisplayMode() {
    const isStandalone = window.matchMedia(
      "(display-mode: standalone)"
    ).matches;
    if (navigator.standalone || isStandalone) {
      return "standalone";
    }
    return "browser";
  }

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("beforeinstallprompt", handler);
  }, []);

  const onInstallButtonClick = () => {
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };

  const logoutHandler = () => {
    if (isAuthenticated) {
      logout();
    }
  };

  const appMode = getPWADisplayMode();

  const [showNotifications, setShowNotifications] = useState(false);
  const handleNotificationClick = (e) => {
    !showNotifications && e.stopPropagation();
    setShowNotifications(true);
  };

  const [showCreateNotificationModal, setShowCreateNotificationModal] =
    useState(false);
  const handleCreateNotificationModal = (e) => {
    setShowCreateNotificationModal(true);
  };

  let nonCollapsableItems = [
    {
      label: (
        <Notifications
          show={showNotifications}
          setShow={setShowNotifications}
        />
      ),
      handler: handleNotificationClick,
    },
  ];

  let items = [
    {
      label: "Mitt konto",
      handler: () =>
        (window.location.href = `${window.env.authority}/manage?returnUrl=${window.location.href}`),
    },
    { label: "Logga ut", handler: logoutHandler },
  ];

  if (appMode !== "standalone" && (isMobile || isTablet) && supportsPWA) {
    items.unshift({
      label: "Installera App",
      handler: onInstallButtonClick,
    });
  }

  if (clinicRole === "DoctorUser" || clinicRole === "SecretaryUser") {
    items.unshift({
      label: "Skapa notis",
      handler: handleCreateNotificationModal,
    });
  }

  let logo = themeSettings.logo || themeSettings.theme.logo;
  let logoStyle;
  if (themeSettings.logoStyle) {
    logoStyle = {
      height: themeSettings.logoStyle.height,
      marginTop: themeSettings.logoStyle.marginTop,
      marginBottom: themeSettings.logoStyle.marginBottom,
    };
  }

  return (
    <>
      <NavBar
        logo={logo}
        logoStyle={logoStyle}
        logohandler={() =>
          (window.location.href = EXTERNAL_URLS.CAREGIVER_PORTAL_URL)
        }
        nonCollapsableItems={nonCollapsableItems}
        items={items}
      />
      <CreateNotificationModal
        show={showCreateNotificationModal}
        setShow={setShowCreateNotificationModal}
      />
    </>
  );
};

export default NavMenu;
