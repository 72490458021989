import {OidcUserStatus, useOidcUser} from "@axa-fr/react-oidc";
import {LoadingView} from "@collabodoc/component-library";
import {Navigate} from "react-router-dom";
import {SITE_URLS} from "../../enums/Urls";
import usePatientListFilters from "../../hooks/usePatientListFilters";

const PatientListRedirect = () => {
    const {oidcUserLoadingState} = useOidcUser();
    const {getDefaultFilters} = usePatientListFilters()
    const url = `${SITE_URLS.PATIENT_LIST}?${getDefaultFilters().toString()}`;

    if (oidcUserLoadingState === OidcUserStatus.Loaded) {
        return <Navigate to={url} replace/>;
    }
    else return <LoadingView/>;
}

export default PatientListRedirect;
