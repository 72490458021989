import { useOidcAccessToken, useOidcUser } from "@axa-fr/react-oidc";
import {
  getClinicRole,
  getRequestInit,
  useFetchy,
} from "@collabodoc/component-library";
import {
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from "@microsoft/signalr";
import { createContext, useEffect, useMemo } from "react";
import { API_URLS } from "../enums/Urls";

export const MedicalRoundContext = createContext({});

export const MedicalRoundContextProvider = ({ children }) => {
  const { accessToken } = useOidcAccessToken();
  const { oidcUser } = useOidcUser();
  const clinicRole = getClinicRole(oidcUser?.role);
  const {
    data: careCenters = [],
    isLoading: isLoadingCareCenters,
    doFetch: reFetchCareCenters,
  } = useFetchy(API_URLS.CARE_CENTERS, getRequestInit({ accessToken }));

  const { data: contactReasons = [] } = useFetchy(
    API_URLS.CONTACT_REASONS,
    getRequestInit({ accessToken })
  );

  const url = new URL(API_URLS.MEASURE_TEMPLATES, document.baseURI);
  if (clinicRole) {
    url.searchParams.append("role", clinicRole);
  }
  const { data: measureTemplates = [] } = useFetchy(
    url.toString(),
    getRequestInit({ accessToken })
  );

  const hubConnection = useMemo(
    () =>
      new HubConnectionBuilder()
        .withUrl(API_URLS.ISSUE_SIGNALR_HUB)
        .configureLogging(LogLevel.Warning)
        .withAutomaticReconnect()
        .build(),
    [API_URLS.ISSUE_SIGNALR_HUB]
  );

  useEffect(() => {
    if (hubConnection.state === HubConnectionState.Disconnected) {
      hubConnection
        .start()
        .then(() => console.log("SignalR connection started."));
    }
    hubConnection.onreconnected(() => console.log("SignalR reconnected."));
    hubConnection.onclose(() => console.log("Failed to connect."));
  }, [hubConnection]);

  return (
    <MedicalRoundContext.Provider
      value={{
        careCenters,
        reFetchCareCenters,
        isLoadingCareCenters,
        hubConnection,
        contactReasons,
        measureTemplates,
        oidcUser,
      }}
    >
      {children}
    </MedicalRoundContext.Provider>
  );
};
