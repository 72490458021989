import { faNoteSticky } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  createSearchParams,
  generatePath,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";
import { device } from "../../Content/Style/devices";
import { FILTER_KEYS } from "../../enums/enums";
import { SITE_URLS } from "../../enums/Urls";
import getStatusIcon from "../../functions/getIcon";
import UsePatientListFilters from "../../hooks/usePatientListFilters";
import { formatDateTime } from "../../utils/dateUtils";
import { IssueIdKey } from "../PatientView/PatientView";
import DropDownMenu from "./DropDown";
import {
  isIssue,
  PatientListIssueModel,
  PatientListPatientModel,
  PatientListReminderModel,
} from "../../interfaces";
import { PrimaryButton, TableBodyRow, Td } from "@collabodoc/component-library";

interface CollapsableRowProps {
  patient: PatientListPatientModel;
}

const CollapsableRow = ({ patient }: CollapsableRowProps) => {
  const navigate = useNavigate();

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const { getFilterValues } = UsePatientListFilters();
  const currentStatuses = getFilterValues(FILTER_KEYS.STATUS);
  const roles = [
    ...new Set(patient.issues.map((issue) => issue.currentRole)),
  ].sort();

  const issuesAndReminders: Array<
    PatientListIssueModel | PatientListReminderModel
  > = [];
  patient.issues.length && issuesAndReminders.push(...patient.issues);
  patient.reminders && issuesAndReminders.push(patient.reminders);

  const sortedIssuesAndReminders = issuesAndReminders.sort((a, b) => {
    return (
      new Date(b.timeOfLastActivity).valueOf() -
      new Date(a.timeOfLastActivity).valueOf()
    );
  });

  const hasMultipleRows = sortedIssuesAndReminders.length > 1;
  const hasMultipleReminders =
    patient.reminders && patient.reminders.reminderCount > 1;
  const isTablet = useMediaQuery({ query: device.tablet });
  const isLaptopS = useMediaQuery({ query: device.laptopS });
  const isMobileL = useMediaQuery({ query: device.mobileL });

  useEffect(() => {
    isLaptopS && setIsCollapsed(true);
  }, [isLaptopS]);

  const handleDropDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setDropDownOpen(!dropDownOpen);
  };

  const handleCollapse = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsCollapsed(!isCollapsed);
  };

  const handlePatientClick = () => {
    const isReminder = !isIssue(issuesAndReminders[0]);
    navigate(generatePath(SITE_URLS.PATIENT_VIEW, { patientId: patient.id }), {
      state: { reminderModalOpen: isReminder },
    });
  };
  const handleIssueRowClick = (issueId: string) => {
    navigate(
      {
        pathname: generatePath(SITE_URLS.PATIENT_VIEW, {
          patientId: patient.id,
        }),
        search: createSearchParams({ [IssueIdKey]: issueId }).toString(),
      },
      { state: { reminderModalOpen: false } }
    );
  };

  const handleReminderRowClick = () => {
    navigate(
      {
        pathname: generatePath(SITE_URLS.PATIENT_VIEW, {
          patientId: patient.id,
        }),
      },
      { state: { reminderModalOpen: true } }
    );
  };

  const showStatus = !isMobileL && currentStatuses.length !== 1;
  return (
    <TableBody>
      <TableBodyRow
        onClick={() =>
          hasMultipleRows
            ? handlePatientClick()
            : isIssue(sortedIssuesAndReminders[0])
            ? handleIssueRowClick(patient.issues[0].issueId)
            : handlePatientClick()
        }
      >
        {!isLaptopS && (
          <Td>
            <DropDownMenu
              showMenu={dropDownOpen}
              toggleMenu={handleDropDown}
              handleRowClick={handlePatientClick}
              setDropDownOpen={setDropDownOpen}
            />
          </Td>
        )}
        <StyledTd>
          {patient.hasNote && (
            <IconWrapper>
              <FontAwesomeIcon color={"#b3b3b3"} icon={faNoteSticky} />
            </IconWrapper>
          )}
        </StyledTd>
        <StyledTd>{patient.name}</StyledTd>
        <StyledTd>{patient.personalNumber}</StyledTd>
        <StyledTd>{patient.unit.careCenterName}</StyledTd>
        <StyledTd>{patient.unit.name}</StyledTd>
        {!isLaptopS && (
          <Td>
            {hasMultipleRows ? (
              <SmallPrimaryButton
                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                  handleCollapse(e)
                }
              >
                {isCollapsed
                  ? `Visa ${
                      patient.issues.length +
                      (patient.reminders && patient.reminders.reminderCount)
                    }`
                  : `Dölj`}
              </SmallPrimaryButton>
            ) : isIssue(sortedIssuesAndReminders[0]) ? (
              patient.issues[0].contactReason
            ) : hasMultipleReminders ? (
              `Aktuella påminnelser (${patient.reminders.reminderCount})`
            ) : (
              "Aktuell påminnelse"
            )}
          </Td>
        )}
        {!isTablet && (
          <Td>
            {formatDateTime(sortedIssuesAndReminders[0].timeOfLastActivity)}
          </Td>
        )}
        {showStatus && (
          <ImageTd>
            {hasMultipleRows ? (
              isCollapsed &&
              roles.map((currentRole, index) => {
                return (
                  <Image key={index} alt="" src={getStatusIcon(currentRole)} />
                );
              })
            ) : (
              <Image
                alt=""
                src={getStatusIcon(sortedIssuesAndReminders[0].currentRole)}
              />
            )}
          </ImageTd>
        )}
      </TableBodyRow>
      {!isCollapsed &&
        sortedIssuesAndReminders.map((entity, index) => (
          <TableBodyRow
            onClick={() =>
              isIssue(entity)
                ? handleIssueRowClick(entity.issueId)
                : handleReminderRowClick()
            }
            key={index}
          >
            <Td></Td>
            {!isLaptopS && <Td></Td>}
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            {!isLaptopS && (
              <Td>
                {isIssue(entity)
                  ? entity.contactReason
                  : entity.reminderCount > 1
                  ? `Aktuella påminnelser (${entity.reminderCount})`
                  : `Aktuell påminnelse`}
              </Td>
            )}
            {!isTablet && <Td>{formatDateTime(entity.timeOfLastActivity)}</Td>}
            {showStatus && (
              <ImageTd>
                {isIssue(entity) && (
                  <Image alt="" src={getStatusIcon(entity.currentRole)} />
                )}
              </ImageTd>
            )}
          </TableBodyRow>
        ))}
    </TableBody>
  );
};

export default CollapsableRow;

const TableBody = styled.tbody`
  border-top: 0 !important;

  :nth-child(even) > tr {
    background-color: ${({ theme }) =>
      theme.table.row.odd.backgroundColor} !important;

    :hover {
      background-color: ${({ theme }) =>
        theme.table.row.hover.backgroundColor} !important;
    }
  }

  :nth-child(odd) > tr {
    background-color: ${({ theme }) =>
      theme.table.row.even.backgroundColor} !important;

    :hover {
      background-color: ${({ theme }) =>
        theme.table.row.hover.backgroundColor} !important;
    }
  }
`;

const SmallPrimaryButton = styled(PrimaryButton)`
  font-size: 0.8rem;
  padding: 0.25rem 0.5rem;
`;

const StyledTd = styled(Td)`
  @media ${device.tablet} {
    padding-left: 5px !important;
    padding-right: 5px !important;
    font-size: clamp(12px, 2.4vw, 16px);
  }
`;

const ImageTd = styled(Td)`
  width: 140px;
  text-align: right;
`;
const Image = styled.img`
  margin: 2px;
  height: 25px;
`;

const IconWrapper = styled.span`
  align-items: center;
`;
