import { useOidcUser } from "@axa-fr/react-oidc";
import { getClinicRole } from "@collabodoc/component-library";
import { useContext } from "react";
import { MedicalRoundContext } from "../../context/MedicalRoundContext";
import { ISSUE_STATUS, USER_ROLES } from "../../enums/enums";
import MessageHistory from "./MessageHistory";
import NewMessage from "./NewMessage";

const Issue = ({ issue, handleIsSelectedIssueSection }) => {
  const { contactReasons } = useContext(MedicalRoundContext);
  const { oidcUser } = useOidcUser();
  const clinicRole = getClinicRole(oidcUser?.role);
  const isIssueOpen = issue && issue.status === ISSUE_STATUS.OPEN;
  const roleHasContactReasons = contactReasons.some(
    (contactReason) => contactReason.fromRole === USER_ROLES[clinicRole]
  );
  const roleIsInIssue =
    issue &&
    (issue.currentRole === USER_ROLES[clinicRole] ||
      issue.otherRole === USER_ROLES[clinicRole]);

  return (
    <>
      {issue && (
        <MessageHistory
          issue={issue}
          isIssueOpen={isIssueOpen}
          roleIsInIssue={roleIsInIssue}
        />
      )}
      {((!issue && roleHasContactReasons) || roleIsInIssue) && (
        <NewMessage
          issue={issue}
          handleIsSelectedIssueSection={handleIsSelectedIssueSection}
          isIssueOpen={isIssueOpen}
        />
      )}
    </>
  );
};

export { Issue };
