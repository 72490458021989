import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  CheckboxWithLabel,
  doFetch,
  DropDown,
  getRequestInit,
  H2,
  LoadingView,
  PrimaryButton,
  SecondaryButton,
  TextInputWithLabelAndError,
  useFetchy,
} from "@collabodoc/component-library";
import Personnummer from "personnummer";
import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { generatePath, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MedicalRoundContext } from "../../context/MedicalRoundContext";
import { API_URLS, SITE_URLS } from "../../enums/Urls";
import { sortAscendingByKey } from "../../functions/sortFunctions";

const CreatePatientModal = ({ showCreateIssueModal, hideCreateIssueModal }) => {
  const [personId, setPersonId] = useState("19");
  const [inputsVisible, setInputsVisible] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isListed, setIsListed] = useState(false);
  const [hasPersonalNumber, setHasPersonalNumber] = useState(true);

  const [pnrLengthError, setShowPnrLengthError] = useState(false);
  const [pnrValidityError, setShowPnrValidityError] = useState(false);
  const [patientExistsError, setShowPatientExistsError] = useState(false);

  const [careCenterId, setCareCenterId] = useState(null);
  const [selectedCareCenterName, setSelectedCareCenterName] = useState(null);
  const [selectedCareCenter, setSelectedCareCenter] = useState(null);
  const [selectedUnitName, setSelectedUnitName] = useState(null);
  const [unitId, setUnitId] = useState(null);

  const navigate = useNavigate();
  const { accessToken } = useOidcAccessToken();
  const [allowEdit, setAllowEdit] = useState(true);
  const {
    doFetch: getPatient,
    data: patient,
    isLoading: isLoadingPatient,
  } = useFetchy();

  const { careCenters = [], isLoadingCareCenters } =
    useContext(MedicalRoundContext);

  useEffect(() => {
    careCenterId &&
      setSelectedCareCenter(
        careCenters.find((x) => x.careCenterId === careCenterId)
      );
    const ccname =
      careCenterId &&
      careCenters.find((x) => x.careCenterId === careCenterId).name;
    setSelectedCareCenterName(ccname);
    const unitname =
      unitId &&
      careCenters
        .find((x) => x.careCenterId === careCenterId)
        .units.find((x) => x.unitId === unitId).name;
    setSelectedUnitName(unitname);
  }, [careCenterId, unitId]);

  useEffect(() => {
    if (patient) {
      setFirstName(patient.firstName);
      setLastName(patient.lastName);
      setCareCenterId(patient.unit.careCenterId);
      setIsListed(patient.isListed);
      setUnitId(patient.unit.unitId);
      setAllowEdit(false);
    } else {
      setAllowEdit(true);
      clearForm();
    }
  }, [patient]);

  const checkPersonalNumber = (pnr, hasPnr) => {
    setPersonId(pnr);
    let pnrWithoutHyphen = pnr.replace("-", "");
    if (!pnr) {
      return;
    }
    if (hasPnr) {
      if (pnrWithoutHyphen.length !== 12) {
        setShowPnrValidityError(false);
        setShowPnrLengthError(true);
        setInputsVisible(false);
      } else {
        setShowPnrLengthError(false);
        if (
          Personnummer.valid(pnrWithoutHyphen, {
            allowCoordinationNumber: false,
          })
        ) {
          const init = getRequestInit({ accessToken });
          init.headers.personId = pnrWithoutHyphen;
          init.headers.hasPersonalNumber = true;
          getPatient(API_URLS.PATIENT_BY_PERSON_ID, init).then(() => {
            setInputsVisible(true);
          });
        } else {
          setInputsVisible(false);
          clearForm();
          setShowPnrValidityError(true);
        }
      }
    } else {
      const init = getRequestInit({ accessToken });
      init.headers.personId = pnrWithoutHyphen;
      init.headers.hasPersonalNumber = Personnummer.valid(pnrWithoutHyphen, {
        allowCoordinationNumber: false,
      });
      getPatient(API_URLS.PATIENT_BY_PERSON_ID, init).then(
        ({ data: patient }) => {
          if (patient && patient.hasPersonalNumber) {
            clearForm();
            setShowPatientExistsError(true);
            setInputsVisible(false);
          } else {
            setShowPatientExistsError(false);
            setInputsVisible(true);
          }
        }
      );
    }
  };

  const toggleHasPersonalNumber = (hasPnr) => {
    clearForm();
    setInputsVisible(false);
    setHasPersonalNumber(hasPnr);
    if (personId && personId !== "19") {
      checkPersonalNumber(personId, hasPnr);
    } else {
      if (hasPnr) {
        setPersonId("19");
      } else {
        setPersonId("");
      }
    }
  };

  const handleHide = () => {
    clearForm();
    setInputsVisible(false);
    setHasPersonalNumber(true);
    setPersonId("19");
    hideCreateIssueModal();
  };

  const clearForm = () => {
    setFirstName("");
    setLastName("");
    setCareCenterId(null);
    setUnitId(null);
    setIsListed(false);
    setSelectedCareCenter(null);
    setSelectedUnitName(null);
    setShowPnrLengthError(false);
    setShowPnrValidityError(false);
    setShowPatientExistsError(false);
  };

  const handleCreatePatient = async () => {
    if (patient) {
      navigate(
        generatePath(SITE_URLS.PATIENT_VIEW, { patientId: patient.id }),
        {
          state: {
            isNewIssue: true,
          },
        }
      );
    } else {
      const data = {
        unitId: unitId,
        personId: personId,
        firstname: firstName,
        lastname: lastName,
        listed: isListed,
        hasPersonalNumber,
      };
      await doFetch(
        API_URLS.PATIENTS,
        getRequestInit({ accessToken, method: "POST", data })
      ).then(({ data = {} }) => {
        navigate(
          generatePath(SITE_URLS.PATIENT_VIEW, {
            patientId: data.patientId,
          }),
          {
            state: {
              isNewIssue: true,
            },
          }
        );
      });
    }
  };

  const sortedCareCenters =
    careCenters && sortAscendingByKey(careCenters, "name");
  const sortedSelectedCareCenters =
    selectedCareCenter && sortAscendingByKey(selectedCareCenter.units, "name");

  let disabled =
    !personId || !firstName || !lastName || !careCenterId || !unitId;

  return (
    <>
      {(isLoadingPatient || isLoadingCareCenters) && <LoadingView />}
      <Modal show={showCreateIssueModal} onHide={handleHide}>
        <Modal.Header>
          <H2>Ny patient</H2>
        </Modal.Header>
        <Modal.Body>
          <ModalInputDiv>
            <ModalInputLabel>
              {hasPersonalNumber
                ? "Personnummer (ÅÅÅÅMMDD-XXXX)"
                : "Reservnummer / samordningsnummer / födelsenummer"}
            </ModalInputLabel>
            <TextInputWithLabelAndError
              maxLength={20}
              placeholder={
                hasPersonalNumber
                  ? "ÅÅÅÅMMDD-XXXX"
                  : "Reservnummer / samordningsnummer / födelsenummer"
              }
              value={personId}
              handleChange={(e) => checkPersonalNumber(e, hasPersonalNumber)}
            />
            {pnrLengthError && (
              <InputError>
                Ange personnummer med 12 siffror (ÅÅÅÅMMDD-XXXX)
              </InputError>
            )}
            {pnrValidityError && <InputError>Ogiltigt personnummer</InputError>}
            {patientExistsError && (
              <InputError>
                En patient med detta personnummer finns redan
              </InputError>
            )}
            <CheckboxWithLabel
              label={"Personnummer saknas"}
              handleChange={() => toggleHasPersonalNumber(!hasPersonalNumber)}
            />
          </ModalInputDiv>

          {inputsVisible && (
            <>
              <ModalInputDiv>
                <ModalInputLabel>Förnamn</ModalInputLabel>
                <TextInputWithLabelAndError
                  disabled={!allowEdit}
                  value={firstName}
                  handleChange={(e) => setFirstName(e)}
                />
              </ModalInputDiv>
              <ModalInputDiv>
                <ModalInputLabel>Efternamn</ModalInputLabel>
                <TextInputWithLabelAndError
                  disabled={!allowEdit}
                  value={lastName}
                  handleChange={(e) => setLastName(e)}
                />
              </ModalInputDiv>
              <ModalInputDiv>
                <ModalInputLabel>Boende</ModalInputLabel>
                <DropDown
                  placeholder={"Välj boende"}
                  options={sortedCareCenters.map((x) => ({
                    text: x.name,
                    id: x.careCenterId,
                  }))}
                  handler={(id) => {
                    setCareCenterId(id);
                    setUnitId(null);
                  }}
                  sort={true}
                  selected={selectedCareCenterName}
                  isDisabled={!allowEdit}
                />
              </ModalInputDiv>
              <ModalInputDiv>
                <ModalInputLabel>Avdelning</ModalInputLabel>
                {selectedCareCenter ? (
                  <DropDown
                    placeholder={"Välj avdelning"}
                    options={sortedSelectedCareCenters.map((unit) => ({
                      text: unit.name,
                      id: unit.unitId,
                    }))}
                    handler={(id) => setUnitId(id)}
                    selected={selectedUnitName}
                    isDisabled={!allowEdit}
                  />
                ) : (
                  <DisabledDropDownWrapper>
                    <DropDown placeholder={"Välj avdelning"} />
                  </DisabledDropDownWrapper>
                )}
              </ModalInputDiv>
              <ModalInputDiv>
                <CheckboxWithLabel
                  label={"Patienten är listad"}
                  checked={isListed}
                  handleChange={() => setIsListed(!isListed)}
                  disabled={!allowEdit}
                />
              </ModalInputDiv>
              <div>{`Kontrollera att du har skrivit in rätt namn och ${
                hasPersonalNumber ? "personnummer" : "identitetsbeteckning"
              }`}</div>
            </>
          )}
        </Modal.Body>
        <Footer>
          <SecondaryButton onClick={handleHide}>Avbryt</SecondaryButton>
          <PrimaryButton
            disabled={disabled}
            onClick={() => handleCreatePatient()}
          >
            {allowEdit ? "Lägg till patient" : "Gå till patient"}
          </PrimaryButton>
        </Footer>
      </Modal>
    </>
  );
};

export default CreatePatientModal;

const ModalInputLabel = styled.strong`
  font-style: bold;
`;

const ModalInputDiv = styled.div`
  margin-bottom: 10px;
`;

const DisabledDropDownWrapper = styled.div`
  pointer-events: none;
  filter: grayscale(1);
`;

const Footer = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between;
`;

const InputError = styled.i`
  font-size: 14px;
`;
