import NurseIcon from "../Content/Images/Icons/nurse_light.svg";
import ClosedIcon from "../Content/Images/Icons/lock_gray_svg.svg";
import DoctorIcon from "../Content/Images/Icons/doctor_blue.svg";
import SecretaryIcon from "../Content/Images/Icons/secretary_blue.svg";
import { USER_ROLES } from "../enums/enums";

const getStatusIcon = (currentRole: number | undefined): string => {
  if (!currentRole) {
    return ClosedIcon;
  }

  switch (currentRole) {
    case USER_ROLES.NurseUser:
      return NurseIcon;
    case USER_ROLES.DoctorUser:
      return DoctorIcon;
    case USER_ROLES.SecretaryUser:
      return SecretaryIcon;
    default:
      return "";
  }
};

export default getStatusIcon;
