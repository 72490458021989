import capio from "./capio";
import defaultTheme from "./defaultTheme";
import ptj from "./ptj";

export const getTheme = () => {
    const themeSetting = window.env.theme;
    const logoSetting = window.env.overrideLogo;

    let theme = "";
    let logo = "";
    let logoStyle = "";

    switch (themeSetting.trim()) {
        case "capio":
            theme = capio;
            break;
        case "ptj":
            theme = ptj;
            break;
        default:
            theme = defaultTheme;
    }

    switch (logoSetting.trim()) {
        case "capiocollabodoc":
            logo = capio.overrideLogo;
            logoStyle = capio.overrideLogoStyle && capio.overrideLogoStyle;
            break;
        case "ptjcollabodoc":
            logo = ptj.overrideLogo;
            logoStyle = ptj.overrideLogoStyle && ptj.overrideLogoStyle;
            break;
    }

    return {theme: theme, logo: logo, logoStyle: logoStyle};
};
