import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { getRequestInit, useFetchy } from "@collabodoc/component-library";
import { useContext, useEffect } from "react";
import { MedicalRoundContext } from "../context/MedicalRoundContext";
import { API_URLS } from "../enums/Urls";

export const usePatientIssuesSubscription = (patientId) => {
  const { accessToken } = useOidcAccessToken();
  const { hubConnection } = useContext(MedicalRoundContext);
  const {
    data: issues = [],
    isLoading,
    doFetch,
  } = useFetchy(
    API_URLS.PATIENT_ISSUES(patientId),
    getRequestInit({ accessToken })
  );

  useEffect(() => {
    if (hubConnection && hubConnection.state === "Connected") {
      hubConnection
        .invoke("SubscribeToPatient", patientId)
        .then(() => console.debug("Subscribed to patient updates."))
        .catch((err) => console.error(err.toString()));
      hubConnection.on("refresh", doFetch);
    }
    return () => {
      if (hubConnection && hubConnection.state === "Connected") {
        hubConnection.off("refresh", doFetch);
        hubConnection
          .invoke("UnsubscribeFromPatient", patientId)
          .then(() => console.debug("Unsubscribed from patient updates."))
          .catch((err) => console.error(err.toString()));
      }
    };
  }, [hubConnection.state, doFetch, patientId]);

  return {
    issues,
    isLoading,
  };
};
