import { useOidcUser } from "@axa-fr/react-oidc";
import { Table, Th } from "@collabodoc/component-library";
import { useCallback, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled, { createGlobalStyle } from "styled-components";
import { device } from "../../Content/Style/devices";
import { FILTER_KEYS } from "../../enums/enums";
import usePagination from "../../hooks/usePagination";
import usePatientListFilters from "../../hooks/usePatientListFilters";
import { usePatientListSubscription } from "../../hooks/usePatientListSubscription";
import { filterPatients } from "../../utils/patientListUtils";
import CollapsableRow from "./CollapsableRow";
import CreatePatientModal from "./CreatePatientModal";
import { PageHeader } from "./PageHeader";
import Pagination from "./Pagination";
import { SideMenu } from "./SideMenu";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #FFFFFF;
  }
`;

const PatientListView = () => {
  const { getFilterValues } = usePatientListFilters();
  const currentStatuses = getFilterValues(FILTER_KEYS.STATUS);
  const [searchText, setSearchText] = useState(null);
  const [showSideMenu, setShowSideMenu] = useState(false);
  const { patients } = usePatientListSubscription();
  const filteredPatients = filterPatients(patients, searchText);
  const { page, pageCount, pageItems, resetPagination, itemsPerPage } =
    usePagination(filteredPatients, 10);
  const isTablet = useMediaQuery({ query: device.tablet });
  const isLaptopS = useMediaQuery({ query: device.laptopS });
  const isMobileM = useMediaQuery({ query: device.mobileM });
  const oidc = useOidcUser();

  const handleSearchInput = useCallback(
    (e) => {
      resetPagination();
      setSearchText(e);
    },
    [resetPagination]
  );

  const [showCreateIssueModal, setShowCreateIssueModal] = useState(false);

  return (
    <>
      <GlobalStyle />
      <ListContent>
        {oidc.oidcUser && (
          <SideMenu
            showSideMenu={showSideMenu}
            setShowSideMenu={() => setShowSideMenu(false)}
          />
        )}
        <Content sideMenuOpen={showSideMenu}>
          <PageHeader
            setShowCreateIssueModal={setShowCreateIssueModal}
            setShowSideMenu={setShowSideMenu}
            showSideMenu={showSideMenu}
            handleSearchInput={handleSearchInput}
          />
          <Table>
            <thead>
              <tr>
                {!isLaptopS && <Th>Hantera</Th>}
                <Th width="15px"></Th>
                <StyledTh>Namn</StyledTh>
                <StyledTh>Personnummer</StyledTh>
                <StyledTh>Boende</StyledTh>
                <StyledTh>Avd.</StyledTh>
                {!isLaptopS && <Th width={"200px"}>Ärende</Th>}
                {!isTablet && <Th>Senaste händelse</Th>}
                {currentStatuses.length !== 1 && !isMobileM && (
                  <StyledTh></StyledTh>
                )}
              </tr>
            </thead>
            {pageItems.map((patient, index) => (
              <CollapsableRow key={index} patient={patient} />
            ))}
          </Table>
          <Pagination
            numberOfPages={pageCount}
            currentPageNumber={page}
            itemsPerPage={itemsPerPage}
            totalItemCount={filteredPatients.length}
          />
          <CreatePatientModal
            showCreateIssueModal={showCreateIssueModal}
            hideCreateIssueModal={() => setShowCreateIssueModal(false)}
          />
        </Content>
      </ListContent>
    </>
  );
};

const ListContent = styled.div`
  height: 100%;
  display: flex;
`;

const Content = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 0.75rem;
  flex: 5;
  @media ${device.tablet} {
    display: ${(props) => props.sideMenuOpen && "none"};
  }
`;

const StyledTh = styled(Th)`
  @media ${device.tablet} {
    padding-left: 5px !important;
    padding-right: 5px !important;
    font-size: clamp(12px, 2.4vw, 16px);
  }
`;

export default PatientListView;
