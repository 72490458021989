import {P, RadioButtonWithLabel} from "@collabodoc/component-library";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useState} from "react";
import styled from "styled-components";

export const SingleFilter = ({
                                 showFilterDefault = true,
                                 filterHeader,
                                 options,
                                 selectedOption,
                                 setSelectedOption,
                             }) => {

    const [showFilter, setShowFilter] = useState(showFilterDefault);
    
    const handleSetSingleFilter = (id) => {
        setSelectedOption(id)
    }

    return (
        <>
            <Header onClick={() => setShowFilter(!showFilter)}>
                <FilterHeader>{filterHeader}</FilterHeader>
                <FontAwesomeIcon
                    icon={showFilter ? faChevronUp : faChevronDown}
                />
            </Header>
            <FilterOption hidden={!showFilter}>
                {options.map(option =>
                    <RadioButtonWithLabel
                        id={option.id}
                        name={filterHeader}
                        label={option.text}
                        key={option.id}
                        checked={selectedOption === option.id}
                        handleChange={() => handleSetSingleFilter(option.id)}
                    />
                )}
            </FilterOption>
        </>
    )
}

export const Header = styled.div`
background-color: ${props => props.theme.filter.backgroundColor};
padding: 5px 5px 3px 5px;
justify-content: space-between;
border-bottom: 1px solid ${props => props.theme.colors.secondary};
display: flex;
cursor: pointer;
    > svg {
      color: ${props => props.theme.colors.primary};
      margin-top: 4px;
      margin-right: 3px;
    }
`;

export const FilterHeader = styled(P)`
color: ${props => props.theme.filter.color};
margin-bottom: 0;
padding-right: 15px;
margin-left: 5px;
`;

export const FilterOption = styled.div`
margin-left: 15px;
margin-right: 5px;
`;
