import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  CheckboxWithLabel,
  doFetch,
  getRequestInit,
} from "@collabodoc/component-library";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { API_URLS } from "../../enums/Urls";

const MeasureContent = ({ content, issueId, patientId, roleIsInIssue }) => {
  const { accessToken } = useOidcAccessToken();
  const [isCompleted, setIsCompleted] = useState(content.isCompleted);

  useEffect(() => {
    setIsCompleted(content.isCompleted);
  }, [content.isCompleted]);

  const handleToggleCompleted = () => {
    const url = API_URLS.MEASURE_COMPLETED(patientId, issueId, content.id);
    const init = getRequestInit({
      accessToken,
      method: "PUT",
      data: JSON.stringify(!isCompleted),
    });
    doFetch(url, init).catch((err) => console.error(err));
    setIsCompleted(!isCompleted);
  };

  return (
    <MeasureContentWrapper>
      <MeasureText>{content.text}</MeasureText>
      <MeasureCheckboxSection>
        <CheckboxWithLabel
          label={"Utfört"}
          checked={isCompleted}
          disabled={!roleIsInIssue}
          handleChange={() => handleToggleCompleted()}
        />
      </MeasureCheckboxSection>
    </MeasureContentWrapper>
  );
};

const MeasureText = styled.div`
  margin-top: 10px;
`;

const MeasureContentWrapper = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid #0d3050;
  margin-top: 10px;
  justify-content: space-between;
`;

const MeasureCheckboxSection = styled.div`
  margin-bottom: 0px;

  > label {
    margin-top: 6px;
    color: #0d3050;

    > span::before {
      border-radius: 3px;
      background-color: #fff;
    }
  }
`;

export default MeasureContent;
