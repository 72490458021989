import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  DropDown,
  getRequestInit,
  H1,
  H2,
  Input,
  InputLabel,
  PrimaryButton,
  useFetchy,
  ValidationError,
} from "@collabodoc/component-library";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { device } from "../../Content/Style/devices";
import { MedicalRoundContext } from "../../context/MedicalRoundContext";
import { API_URLS } from "../../enums/Urls";

const AdminView = ({ careCenterId }) => {
  const { accessToken } = useOidcAccessToken();
  const { careCenters, reFetchCareCenters } = useContext(MedicalRoundContext);
  const [selectedCareCenterId, setSelectedCareCenterId] =
    useState(careCenterId);
  const [selectedUnitId, setSelectedUnitId] = useState();

  const { doFetch: createUnit, error: createUnitError } = useFetchy();
  const { doFetch: editUnitName, error: editUnitNameError } = useFetchy();

  const firstCareCenterId = careCenters[0]?.careCenterId;
  useEffect(() => {
    setSelectedCareCenterId(selectedCareCenterId || firstCareCenterId);
  }, [selectedCareCenterId, firstCareCenterId]);

  const handleNewUnitSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    const data = Object.fromEntries(new FormData(form).entries());
    createUnit(
      API_URLS.CARE_CENTER_UNITS(selectedCareCenterId),
      getRequestInit({ accessToken, method: "POST", data })
    ).then(() => {
      form.reset();
      reFetchCareCenters();
    });
  };

  const handleEditUnitSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    const data = Object.fromEntries(new FormData(form).entries());
    editUnitName(
      API_URLS.CARE_CENTER_UNIT(selectedCareCenterId, selectedUnitId),
      getRequestInit({ accessToken, method: "PATCH", data })
    ).then(() => {
      reFetchCareCenters();
    });
  };

  const careCenterOptions = careCenters.map((careCenter) => ({
    id: careCenter.careCenterId,
    text: careCenter.name,
  }));
  const selectedCareCenter = careCenters.find(
    (careCenter) => careCenter.careCenterId === selectedCareCenterId
  );

  const unitOptions =
    (selectedCareCenter &&
      selectedCareCenter.units.map((unit) => ({
        id: unit.unitId,
        text: unit.name,
      }))) ||
    [];
  const selectedUnit = unitOptions.find((unit) => unit.id === selectedUnitId);

  return (
    <Container>
      <H1>Administration</H1>
      <H2>Hantera avdelningar</H2>
      <Row>
        <CustomLabel>Välj boende</CustomLabel>
      </Row>
      <Row>
        <DropDown
          options={careCenterOptions}
          selected={selectedCareCenter?.name}
          handler={(id) => setSelectedCareCenterId(id)}
        />
      </Row>
      <Row>
        <CustomLabel>Befintliga avdelningar</CustomLabel>
      </Row>
      <Ul>
        {selectedCareCenter &&
          selectedCareCenter.units.map((unit) => (
            <Li key={unit.unitId}>{unit.name}</Li>
          ))}
      </Ul>
      <Row>
        <CustomLabel htmlFor={"newUnitName"}>Ny avdelning</CustomLabel>
      </Row>
      <Form onSubmit={handleNewUnitSubmit}>
        <Row>
          <Input
            type={"text"}
            id={"newUnitName"}
            name={"unitName"}
            minlength={3}
            required
          />
          <NoWrapButton type={"submit"}>Lägg till</NoWrapButton>
        </Row>
        <Row>
          {createUnitError && <ValidationError>Något gick fel</ValidationError>}
        </Row>
      </Form>
      <Row>
        <CustomLabel>Välj avdelning att ändra</CustomLabel>
      </Row>
      <Row>
        <DropDown
          options={unitOptions}
          selected={selectedUnit?.text}
          handler={(id) => setSelectedUnitId(id)}
        />
      </Row>
      <Form onSubmit={handleEditUnitSubmit}>
        <Row>
          <CustomLabel htmlFor={"editUnitName"}>Nytt namn</CustomLabel>
        </Row>
        <Row key={selectedUnitId}>
          <Input
            type={"text"}
            id={"editUnitName"}
            name={"unitName"}
            defaultValue={selectedUnit?.text}
            minlength={3}
            required
          />
          <NoWrapButton>Ändra</NoWrapButton>
        </Row>
        <Row>
          {editUnitNameError && (
            <ValidationError>Något gick fel</ValidationError>
          )}
        </Row>
      </Form>
    </Container>
  );
};

const Container = styled.main`
  margin-left: 7.5rem;
  margin-right: 7.5rem;
  padding: 1rem;
  background-color: white;

  @media ${device.tablet} {
    margin-left: 0;
    margin-right: 0;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1 auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  & > div {
    flex: 1;
  }
`;

const CustomLabel = styled(InputLabel)`
  padding-left: 0.75rem;
  margin-bottom: 0;
`;

const Ul = styled.ul`
  padding: 0.75rem;
  margin-bottom: 0;
  list-style-type: none;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-items: center;
`;
const Li = styled.li`
  display: flex;
  flex: 1;
  align-items: center;
`;

const Form = styled.form`
  margin-bottom: 2rem;
`;

const NoWrapButton = styled(PrimaryButton)`
  margin-left: 2rem;
  white-space: nowrap;
`;

export default AdminView;
