/* eslint-disable react-hooks/exhaustive-deps */
import { useOidcAccessToken, useOidcUser } from "@axa-fr/react-oidc";
import { getRequestInit, useFetchy } from "@collabodoc/component-library";
import { useContext, useEffect } from "react";
import { MedicalRoundContext } from "../context/MedicalRoundContext";
import { FILTER_KEYS, STATUS_FILTER } from "../enums/enums";
import { API_URLS } from "../enums/Urls";
import usePatientListFilters from "./usePatientListFilters";

export const usePatientListSubscription = () => {
  const { accessToken } = useOidcAccessToken();
  const { hubConnection } = useContext(MedicalRoundContext);
  const { getFilterValue, getFilterValues } = usePatientListFilters();
  const careCenterIds = getFilterValues(FILTER_KEYS.CARE_CENTER_ID);
  const unitIds = getFilterValues(FILTER_KEYS.UNIT_ID);
  const currentStatuses = getFilterValues(FILTER_KEYS.STATUS);
  const showOnlyPatientsWithNotes = getFilterValue(FILTER_KEYS.ONLY_WITH_NOTES);
  const currentContactReasons = getFilterValues(FILTER_KEYS.CONTACT_REASON);
  const { oidcUser } = useOidcUser();
  const searchParams = new URLSearchParams();

  if (currentStatuses.includes(STATUS_FILTER.COMPLETED)) {
    searchParams.append("status", STATUS_FILTER.COMPLETED);
  }

  careCenterIds.forEach((cc) => searchParams.append("careCenterIds", cc));
  unitIds.forEach((unit) => searchParams.append("unitIds", unit));
  currentStatuses
    .filter((status) => status !== STATUS_FILTER.COMPLETED)
    .forEach((status) => searchParams.append("roles", `${status}User`));
  currentContactReasons.forEach((cr) =>
    searchParams.append("contactReason", cr)
  );

  if (showOnlyPatientsWithNotes) {
    searchParams.append(
      "onlyPatientsWithNote",
      showOnlyPatientsWithNotes.toString()
    );
  }

  const {
    data: patients = [],
    isLoading,
    doFetch,
  } = useFetchy(
    `${API_URLS.PATIENTS}?${searchParams.toString()}`,
    getRequestInit({ accessToken })
  );

  const careCentersToSubscribeTo =
    (careCenterIds.length > 0 && careCenterIds) ||
    (oidcUser &&
      (Array.isArray(oidcUser.carecenter)
        ? oidcUser.carecenter
        : [oidcUser.carecenter])) ||
    [];

  useEffect(() => {
    if (
      hubConnection &&
      hubConnection.state === "Connected" &&
      careCentersToSubscribeTo.length > 0
    ) {
      hubConnection
        .invoke(
          "SubscribeToPatientList",
          careCentersToSubscribeTo.map((id) => Number(id))
        )
        .then(() => console.debug("Subscribed to patient list updates."))
        .catch((err) => console.error(err.toString()));
      hubConnection.on("refresh", doFetch);
    }
    return () => {
      if (
        hubConnection &&
        hubConnection.state === "Connected" &&
        careCentersToSubscribeTo.length > 0
      ) {
        hubConnection.off("refresh", doFetch);
        hubConnection
          .invoke(
            "UnsubscribeFromPatientList",
            careCentersToSubscribeTo.map((id) => Number(id))
          )
          .then(() => console.debug("Unsubscribed from patient list updates."))
          .catch((err) => console.error(err.toString()));
      }
    };
  }, [hubConnection.state, doFetch, careCentersToSubscribeTo]);

  return {
    patients,
    isLoading,
  };
};
