import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  doFetch,
  getRequestInit,
  H2,
  SecondaryButton,
  SuccessButton,
  TextAreaWithLabelAndError,
} from "@collabodoc/component-library";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { API_URLS } from "../../enums/Urls";

export const NoteModal = ({
  patientId,
  noteText,
  showModal,
  hideModal,
  reFetchPatient,
}) => {
  const { accessToken } = useOidcAccessToken();
  const [tempNoteText, setTempNoteText] = useState(noteText);
  const handleDeleteClick = () => {
    setTempNoteText("");
  };

  const handleYesClick = () => {
    doFetch(
      API_URLS.PATIENT_NOTE_EDIT(patientId),
      getRequestInit({
        accessToken,
        method: "PATCH",
        data: { noteText: tempNoteText },
      })
    ).then(() => {
      reFetchPatient();
      hideModal();
    });
  };

  const handleCloseModal = () => {
    setTempNoteText(noteText);
    hideModal();
  };

  return (
    <Modal size={"lg"} show={showModal} onHide={() => handleCloseModal()}>
      <Modal.Header>
        <StyledH2>Privat anteckning</StyledH2>
      </Modal.Header>
      <Modal.Body>
        <TextSection>
          <TextAreaWithLabelAndError
            maxRows={12}
            minRows={12}
            handleChange={(e) => setTempNoteText(e)}
            value={tempNoteText}
          />
        </TextSection>
        <small>
          Observera att en privat anteckning bara kan ses av dig själv.
        </small>
      </Modal.Body>
      <Footer>
        <LeftButtonsWrapper>
          <SecondaryButton onClick={() => handleCloseModal()}>
            Avbryt
          </SecondaryButton>
          <SecondaryButton onClick={() => handleDeleteClick()}>
            <FontAwesomeIcon icon={faTrashCan} />
          </SecondaryButton>
        </LeftButtonsWrapper>
        <SuccessButton onClick={() => handleYesClick()}>
          Spara anteckning
        </SuccessButton>
      </Footer>
    </Modal>
  );
};

const StyledH2 = styled(H2)`
  margin: 0;
`;

const TextSection = styled.div`
  > textarea {
    border-radius: 5px;
  }
`;

const Footer = styled(Modal.Footer)`
  margin-top: -10px;
  display: flex;
  justify-content: space-between;
`;

const LeftButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: flex-start;
  gap: 5px;
`;
